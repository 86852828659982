<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 5 14"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M57.11,47 L59.83,33.04 L61.69,33.04 L59.05,46.56 C58.99,46.84 58.79,47 58.55,47 L57.11,47 Z"
      transform="translate(-57 -33)"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
