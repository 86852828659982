<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      fill-rule="evenodd"
      d="M18,12 C20.196919,12 22,13.5776959 22,15.5555556 C22,15.8010154 21.8010154,16 21.5555556,16 L17.8119848,16.0003134 C17.9346839,16.4257383 18,16.8722879 18,17.3333333 C18,17.7015232 17.7015232,18 17.3333333,18 L6.66666667,18 C6.29847683,18 6,17.7015232 6,17.3333333 C6,16.8722879 6.06531613,16.4257383 6.1880152,16.0003134 L2.44444444,16 C2.19898456,16 2,15.8010154 2,15.5555556 C2,13.5776959 3.80308103,12 6,12 C7.01844239,12 7.95224737,12.3390534 8.66101942,12.899849 C9.61722814,12.3311556 10.7672811,12 12,12 C13.2327189,12 14.3827719,12.3311556 15.3395085,12.9010096 C16.0477526,12.3390534 16.9815576,12 18,12 Z M6,8 C6.98183956,8 7.77777778,8.79593822 7.77777778,9.77777778 C7.77777778,10.7596173 6.98183956,11.5555556 6,11.5555556 C5.01816044,11.5555556 4.22222222,10.7596173 4.22222222,9.77777778 C4.22222222,8.79593822 5.01816044,8 6,8 Z M18,8 C18.9818396,8 19.7777778,8.79593822 19.7777778,9.77777778 C19.7777778,10.7596173 18.9818396,11.5555556 18,11.5555556 C17.0181604,11.5555556 16.2222222,10.7596173 16.2222222,9.77777778 C16.2222222,8.79593822 17.0181604,8 18,8 Z M12,6 C13.4727593,6 14.6666667,7.19390733 14.6666667,8.66666667 C14.6666667,10.139426 13.4727593,11.3333333 12,11.3333333 C10.5272407,11.3333333 9.33333333,10.139426 9.33333333,8.66666667 C9.33333333,7.19390733 10.5272407,6 12,6 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
