<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M12,12 C16.9430677,12 21,15.5498158 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 C3,15.5498158 7.05693232,12 12,12 Z M15.4796628,15.0227442 C15.0164787,14.9326806 14.6065913,15.0972375 14.2413793,15.3894559 C13.8761673,15.0972375 13.4662799,14.9326806 13.0030959,15.0227442 C12.3231991,15.1549463 12,15.7256749 12,16.3718165 C12,17.2033177 12.5783769,17.9262888 13.6413793,18.7235406 C13.9969349,18.9902073 14.4858238,18.9902073 14.8413793,18.7235406 C15.9043817,17.9262888 16.4827586,17.2033177 16.4827586,16.3718165 C16.4827586,15.7256749 16.1595595,15.1549463 15.4796628,15.0227442 Z M12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
