<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
    viewBox="0 0 32 32"
  >
    <circle cx="16" cy="16" r="15" class="icon-stroked" />
    <path class="icon-stroked" d="M5,5 L19,19 M5,19 L19,5" transform="translate(4 4)" />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
