<template>
  <div class="fullH fullW flex--row flex--row--center flex--align--center">
    <h2>Contact page></h2>
  </div>
</template>

<script>
export default {
  name: "Contact"
};
</script>
