import http from "../../../services/http";

/**
 * @patientId {String} patientId
 * @body {Object} {sort, nbCommunications}
 * @returns {Array<Object>} providers
 */
export const fetchCommunications = (patientId, params) => http.get(`/communications/${patientId}`, { params });

/**
 * @param {Object} data
 * @returns {Array<Object>} providers
 */
export const sendSms = data => http.post("/communications/send-sms", data);

/**
 * @param {String} patientId
 * @body {Object} {smsConsent}
 */
export const togglePatientSmsConsent = (patientId, data) => http.put(`/patients/${patientId}/toggle-sms-consent`, data);
