import http from "../../../services/http";

/**
 * @param {Object} params
 * @returns {Array<Object>} prescriber groups
 */
export default params =>
  new Promise((resolve, reject) => {
    http
      .get("/prescribers-groups", {
        params
      })
      .then(res => {
        if (res.data.body) {
          resolve(res);
        } else {
          /* Because of on 204 status, res.data is an empty string */
          resolve({
            data: {
              body: {
                count: 0,
                results: []
              }
            }
          });
        }
      })
      .catch(err => {
        reject(err);
      });
  });
