import i18n from "@/i18n";

const i18nKey = "router.extranet.login";

const CredentialsPage = () => import(/* webpackChunkName: "public" */ "@/modules/credentials/index");
const login = () => import(/* webpackChunkName: "public" */ "@/modules/login/index.extranet");

export default [
  {
    path: "reset-password/",
    component: CredentialsPage,
    children: [
      {
        path: "patient/:token",
        name: "resetPasswordPatients",
        component: CredentialsPage,
        meta: {
          title: "Renouvellement du mot de passe d'un patient",
          public: true
        }
      }
    ]
  },
  {
    path: "sign-in",
    alias: "connexion",
    name: "login",
    component: login,
    meta: {
      title: i18n.t(`${i18nKey}.title--signIn`),
      public: true
    }
  }
];
