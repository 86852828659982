/* eslint-disable no-param-reassign */
/**
 * Add firstname & lastname if there is only a name property
 *
 * @param {Object} patient
 * @returns {Object}
 */
export default patient => {
  if (!patient.firstname && !patient.lastname) {
    patient.firstname = patient.name;
    patient.lastname = "";
  }
  return patient;
};
