import Vue from "vue";
import VueRouter from "vue-router";

import dashboard from "./routes/extranet/dashboard";
import conversations from "./routes/extranet/conversations";
import login from "./routes/extranet/login";
import footer from "./routes/footer";
import app from "./routes/app";
import register from "./routes/extranet/register";
import patient from "./routes/extranet/patient";
import payment from "./routes/extranet/payment";

import i18n from "./middleware/i18n";
import auth from "./middleware/auth";
import middlewarePipeline from "./middleware/middleware-pipeline";

Vue.use(VueRouter);

const routes = [...login, ...patient, ...payment, ...conversations, ...register, ...dashboard, ...footer, ...app];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:locale([a-zA-Z]{2})?",
      component: {
        render: c => c("router-view")
      },
      children: routes
    }
  ]
});

router.beforeEach((to, from, next) => {
  const middleware = [i18n, auth];
  const context = {
    to,
    from,
    next
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1)
  });
});

export default router;
