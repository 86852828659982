/**
 * @description mapRights takes a full permission object and returns a dictionary of constants
 * ex: { CREATE_PATIENTS: "create_patients"}
 * @param {Object} permissions
 * @return {Object} dictionary of rights
 */
const mapRights = permissions => {
  return Object.keys(permissions.rights).reduce((acc, val) => {
    acc[val] = permissions.rights[val]._id;
    return acc;
  }, {});
};

module.exports = mapRights;
