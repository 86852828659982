<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 L4,12 C4,16.418278 7.581722,20 12,20 C14.2804037,20 16.3379575,19.0458662 17.7952242,17.5150359 M23,11 L20.766663,13.6667981 C20.4120688,14.0902144 19.7813666,14.1460057 19.3579503,13.7914115 C19.3128262,13.7536219 19.2711266,13.7119222 19.233337,13.6667981 L17,11 L17,11"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
