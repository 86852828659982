/**
 * Calculate the total patients count
 *
 * patientsCountByDivision example :
 * { "R": 23, "N": 0, "P": 4, "D": 0 }
 *
 * @param {Object} patientsCountByDivision
 * @returns {Number}
 */
const calculateTotalPatientsCount = patientsCountByDivision => {
  if (!patientsCountByDivision) return 0;
  return Object.keys(patientsCountByDivision).reduce((count, division) => {
    if (typeof patientsCountByDivision[division] === "number") {
      count += patientsCountByDivision[division];
    }
    return count;
  }, 0);
};

export default calculateTotalPatientsCount;
