<template functional>
<svg 
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  :width="props.width"
  :height="props.height"
  :class="`icon-${props.color}`"
>
  <path class="icon-stroked" d="M12,18 C17.5228475,18 22,12 22,12 C22,12 17.5228475,6 12,6 C6.4771525,6 2,12 2,12 C2,12 6.4771525,18 12,18 Z M10,14 L14,10 M10,10 L14,14"/>
</svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
