<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M22,8 L22,18 L17,18 L17,14 L7,14 L7,18 L2,18 L2,8 L22,8 Z M7,14 L17,14 L17,21 L7,21 L7,14 Z M19,10.5 C19.2761424,10.5 19.5,10.7238576 19.5,11 C19.5,11.2761424 19.2761424,11.5 19,11.5 C18.7238576,11.5 18.5,11.2761424 18.5,11 C18.5,10.7238576 18.7238576,10.5 19,10.5 Z M15.5,10.5 C15.7761424,10.5 16,10.7238576 16,11 C16,11.2761424 15.7761424,11.5 15.5,11.5 C15.2238576,11.5 15,11.2761424 15,11 C15,10.7238576 15.2238576,10.5 15.5,10.5 Z M19,4 L19,8 L5,8 L5,4 L19,4 Z M10,17.5 L14,17.5 M6,14 L18,14"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
