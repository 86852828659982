import isEmpty from "lodash/isEmpty";

const buildContactsList = ({
  pole,
  mainTech,
  prescriptionAddress,
  secondaryPrescriptionAddress,
  referralPrescriberAddress,
  familyPrescriberAddress,
  interpretingPhysician,
  otherPrescriptionAddresses,
  contacts
}) => {
  const contactFormatted = [];

  if (!isEmpty(pole)) {
    contactFormatted.push({
      typeId: "pole",
      user: pole
    });
  }

  if (!isEmpty(prescriptionAddress)) {
    contactFormatted.push({
      typeId: "prescriptionAddress",
      user: prescriptionAddress
    });
  }

  if (!isEmpty(mainTech)) {
    contactFormatted.push({
      typeId: "mainTech",
      user: mainTech
    });
  }

  if (!isEmpty(secondaryPrescriptionAddress)) {
    contactFormatted.push({
      typeId: "secondaryPrescriptionAddress",
      user: secondaryPrescriptionAddress
    });
  }

  if (!isEmpty(referralPrescriberAddress)) {
    contactFormatted.push({
      typeId: "referralPrescriber",
      user: referralPrescriberAddress
    });
  }

  if (!isEmpty(familyPrescriberAddress)) {
    contactFormatted.push({
      typeId: "familyPrescriber",
      user: familyPrescriberAddress
    });
  }

  if (!isEmpty(interpretingPhysician)) {
    contactFormatted.push({
      typeId: "interpretingPhysician",
      user: interpretingPhysician
    });
  }

  if (!isEmpty(otherPrescriptionAddresses)) {
    const otherPrescribers = otherPrescriptionAddresses.map(item => ({
      typeId: "otherPrescriptionAddress",
      user: item
    }));

    contactFormatted.push(...otherPrescribers);
  }

  if (!isEmpty(contacts)) {
    contactFormatted.push(...contacts.map(contact => ({ typeId: contact.typeId, user: contact })));
  }

  return contactFormatted;
};

export default buildContactsList;
