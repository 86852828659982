<template functional>
  <svg
    width="18px"
    height="18px"
    viewBox="0 0 18 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Debit-Success" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group" fill="#00C2A3">
        <path
          d="M9,0 C4.03283721,0 0,4.03283721 0,9 C0,13.9671628 4.03283721,18 9,18 C13.9671628,18 18,13.9671628 18,9 C18,4.03283721 13.9671628,0 9,0 Z M9,1.25581395 C13.2739535,1.25581395 16.744186,4.72604651 16.744186,9 C16.744186,13.2739535 13.2739535,16.744186 9,16.744186 C4.72604651,16.744186 1.25581395,13.2739535 1.25581395,9 C1.25581395,4.72604651 4.72604651,1.25581395 9,1.25581395 Z"
          id="Shape"
        ></path>
        <path
          d="M10.4651163,7.31469767 L10.4651163,7.3255814 C10.4651163,7.67218605 10.7464186,7.95348837 11.0930233,7.95348837 C11.0930233,7.95348837 11.7209302,7.90409302 11.7209302,7.31469767 L11.7209302,6.48837209 C11.7209302,5.87804651 11.4781395,5.292 11.0469767,4.86 C10.6149767,4.42883721 10.0289302,4.18604651 9.41860465,4.18604651 C8.14688372,4.18604651 7.11627907,5.21665116 7.11627907,6.48837209 L7.11627907,9.24446512 C7.11627907,9.53665116 7.04846512,9.82548837 6.91702326,10.0866977 C6.91702326,10.0866977 5.508,12.9055814 5.508,12.9055814 C5.41088372,13.099814 5.42093023,13.3308837 5.53562791,13.515907 C5.65032558,13.7009302 5.85209302,13.8139535 6.06976744,13.8139535 L11.0930233,13.8139535 C11.4396279,13.8139535 11.7209302,13.5326512 11.7209302,13.1860465 C11.7209302,12.8394419 11.4396279,12.5581395 11.0930233,12.5581395 L7.08613953,12.5581395 C7.08613953,12.5581395 8.04055814,10.6484651 8.04055814,10.6484651 C8.25823256,10.2122791 8.37209302,9.73172093 8.37209302,9.24446512 L8.37209302,6.48837209 C8.37209302,5.91069767 8.84093023,5.44186047 9.41860465,5.44186047 C9.69655814,5.44186047 9.96195349,5.55237209 10.1586977,5.74827907 C10.3546047,5.94502326 10.4651163,6.2104186 10.4651163,6.48837209 L10.4651163,7.31469767 L10.4651163,7.31469767 L10.4651163,7.31469767 Z"
          id="Path"
        ></path>
        <path
          d="M6.06976744,10.4651163 L11.0930233,10.4651163 C11.4396279,10.4651163 11.7209302,10.183814 11.7209302,9.8372093 C11.7209302,9.49060465 11.4396279,9.20930233 11.0930233,9.20930233 L6.06976744,9.20930233 C5.72316279,9.20930233 5.44186047,9.49060465 5.44186047,9.8372093 C5.44186047,10.183814 5.72316279,10.4651163 6.06976744,10.4651163 Z"
          id="Path"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
