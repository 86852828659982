/* eslint-disable prefer-promise-reject-errors */

import moment from "moment";
import ordoclicDefaultErrorMessages from "@/helpers/_constants/ordoclicDefaultErrorMessages";
import { fetchRenewalFile, printRenewalFile, signRenewalFile } from "@/modules/common/_api";
import * as api from "../_api";
import { formatOrdoclicPatientName } from "./helpers";

const actions = {
  createPatientOrdoclicAccount(context, patient) {
    return new Promise((resolve, reject) => {
      return formatOrdoclicPatientName(patient)
        .then(({ firstName, lastName }) => {
          const { rpps } = context.rootState.login.user;
          if (!rpps) {
            return reject({
              data: {
                errorMessage: ordoclicDefaultErrorMessages.missingRpps
              }
            });
          }
          if (!patient.ssid) {
            return reject({
              data: {
                errorMessage: ordoclicDefaultErrorMessages.missingSsid
              }
            });
          }

          const patientId = patient._id;

          const patientToCreate = {
            id: patient._id,
            birthday: patient.birthDate ? moment.utc(patient.birthDate).format("YYYYMMDD") : moment().format("YYYYMMDD"),
            nir: patient.ssid,
            firstName,
            lastName
          };

          return api
            .createPatientOrdoclicAccount(patientId, patientToCreate, rpps)
            .then(({ data }) => {
              resolve(data.id);
            })
            .catch(error => {
              reject(error);
            });
        })
        .catch(error => {
          reject({
            data: {
              errorMessage: error
            }
          });
        });
    });
  },
  fetchRenewalFile(context, documentId) {
    return new Promise((resolve, reject) => {
      fetchRenewalFile(documentId)
        .then(res => {
          if (!res || !res.data) {
            return reject();
          }
          return resolve({ encodedFile: res.data.file, originalname: res.data.fileName, filename: res.data.fileName, mimetype: "application/pdf" });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  deleteRenewal(context, contractId) {
    return api.deleteRenewal(contractId);
  },
  printRenewalDocument(context, data) {
    const { documentType, ...fillInformations } = data;
    return new Promise((resolve, reject) => {
      printRenewalFile({
        documentType,
        fillInformations
      })
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
  },
  signRenewalDocument(context, data) {
    return new Promise((resolve, reject) => {
      const { documentType, patientOrdoclicId, patientId, ssid, ...fillInformations } = data;
      const { rpps, renewalInfos } = context.rootState.login.user;

      if (!rpps) {
        return reject({
          data: {
            errorMessage: ordoclicDefaultErrorMessages.missingRpps
          }
        });
      }

      if (!ssid || ssid.length !== 15) {
        return reject({
          data: {
            errorMessage: ordoclicDefaultErrorMessages.missingSsid
          }
        });
      }

      return signRenewalFile({
        documentType,
        rpps,
        patientOrdoclicId,
        patientId,
        assignedProId: renewalInfos.ordoclicId,
        fillInformations
      })
        .then(res => {
          return resolve(res.data);
        })
        .catch(err => {
          return reject(err);
        });
    });
  }
};

export default { actions };
