/**
 * fixNumber applies a toFixed with a chosen range of decimals. It also takes care of parseFloat and NaN checks,
 * so a String can safely be passed. If NaN, returns the argument itself
 * @param {Float | String} num
 * @param {Int} decimals
 * @returns {Float | Any}
 */
const fixNumber = (num, decimals = 2) => {
  const safeNumber = Number.parseFloat(num);
  if (!Number.isNaN(safeNumber)) {
    return safeNumber.toFixed(decimals);
  }
  return num;
};

export default fixNumber;
