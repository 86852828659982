<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    class="ic--expand--more"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M20,9 L12.7071068,16.2928932 C12.3165825,16.6834175 11.6834175,16.6834175 11.2928932,16.2928932 L4,9 L4,9"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
