<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M5,19 L19,19 M17,11 L12.7096894,15.3218235 C12.3205967,15.7137741 11.687436,15.7160909 11.2954853,15.3269982 C11.2937541,15.3252796 11.2920292,15.3235547 11.2903106,15.3218235 L7,11 L7,11 M12,15 L12,5"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
