export default (roles = []) => {
  if (!roles || !Array.isArray(roles) || !roles.length) {
    return null;
  }

  return roles.map(role => {
    if (!role) {
      return null;
    }

    return {
      label: `${role.label}`,
      value: role._id
    };
  });
};
