<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M17,19 C17,17.8954305 16.1045695,17 15,17 C13.8954305,17 13,17.8954305 13,19 M12,10 C13.6568542,10 15,8.65685425 15,7 C15,5.34314575 13.6568542,4 12,4 C10.3431458,4 9,5.34314575 9,7 C9,8.65685425 10.3431458,10 12,10 Z M15,17 L15,14 M9,17 L9,14 M9,19 C9.55228475,19 10,18.5522847 10,18 C10,17.4477153 9.55228475,17 9,17 C8.44771525,17 8,17.4477153 8,18 C8,18.5522847 8.44771525,19 9,19 Z M20,20 C20,16.1340068 16.418278,13 12,13 C7.581722,13 4,16.1340068 4,20"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>