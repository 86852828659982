<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M13.0000238,3 C8.08518633,3 4.09049063,6.93959515 4.00153879,11.8331833 L2.76668682,10.3579503 C2.41209257,9.93453402 1.7813904,9.87874272 1.3579741,10.233337 C0.967128287,10.5606547 0.889525076,11.1232361 1.1587968,11.5408786 L1.23336075,11.6420497 L3.46669772,14.3088478 C4.17588621,15.1556804 5.43729054,15.267263 6.28412315,14.5580745 L6.41423735,14.438962 L6.53334985,14.3088478 L8.76668682,11.6420497 C9.12128107,11.2186334 9.06548977,10.5879312 8.64207347,10.233337 C8.25122765,9.9060192 7.68375956,9.92837906 7.31987149,10.2667842 L7.23336075,10.3579503 L6.00402378,11.826 L6.0040828,11.7593502 C6.1309251,8.00478338 9.21457206,5 13.0000238,5 C16.866017,5 20.0000238,8.13400675 20.0000238,12 C20.0000238,15.8659932 16.866017,19 13.0000238,19 C11.0567882,19 9.24280373,18.2055716 7.92909511,16.8255462 C7.54830047,16.4255289 6.91532728,16.4099458 6.51530992,16.7907404 C6.11529256,17.1715351 6.09970947,17.8045083 6.48050412,18.2045256 C8.16791183,19.9771146 10.5035512,21 13.0000238,21 C17.9705865,21 22.0000238,16.9705627 22.0000238,12 C22.0000238,7.02943725 17.9705865,3 13.0000238,3 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
