<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M3,4 L3,20 C3,20.5522847 3.44771525,21 4,21 L20,21 C20.5522847,21 21,20.5522847 21,20 L21,4 C21,3.44771525 20.5522847,3 20,3 L4,3 C3.44771525,3 3,3.44771525 3,4 Z"
    />
    <path
      class="icon-filled"
      d="M10.4888889,8.8 C10.4888889,8.3581722 10.8470611,8 11.2888889,8 L12.7111111,8 C13.1529389,8 13.5111111,8.3581722 13.5111111,8.8 L13.5111111,10.4888889 L15.2,10.4888889 C15.6418278,10.4888889 16,10.8470611 16,11.2888889 L16,12.7111111 C16,13.1529389 15.6418278,13.5111111 15.2,13.5111111 L13.5111111,13.5111111 L13.5111111,15.2 C13.5111111,15.6418278 13.1529389,16 12.7111111,16 L11.2888889,16 C10.8470611,16 10.4888889,15.6418278 10.4888889,15.2 L10.4888889,13.5111111 L8.8,13.5111111 C8.3581722,13.5111111 8,13.1529389 8,12.7111111 L8,11.2888889 C8,10.8470611 8.3581722,10.4888889 8.8,10.4888889 L10.4888889,10.4888889 L10.4888889,8.8 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMedical",
  mixins: [iconMixin]
};
</script>
