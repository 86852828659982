<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M18.6568542,17.6568542 C21.7810486,14.5326599 21.7810486,9.46734008 18.6568542,6.34314575 C15.5326599,3.21895142 10.4673401,3.21895142 7.34314575,6.34314575 L7.34314575,6.34314575 C4.21895142,9.46734008 4.21895142,14.5326599 7.34314575,17.6568542 C8.95563465,19.2693431 11.0852193,20.0495789 13.198123,19.9975616 M13,12 L9,12 M13,12 L16,9 M12,17 L14.6667981,19.233337 C15.0902144,19.5879312 15.1460057,20.2186334 14.7914115,20.6420497 C14.7536219,20.6871738 14.7119222,20.7288734 14.6667981,20.766663 L12,23 L12,23"
      transform="rotate(90 13 12)"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
