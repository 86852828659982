const getOverridedElement = (element, overridedElements) => {
  const overridedElement = overridedElements?.find(el => el.key === element)?.value;
  return overridedElement || element;
};

const componentNameResolver = (component, modulesOverrided) => {
  return getOverridedElement(component, modulesOverrided);
};

const schemaNameResolver = (component, modulesOverrided) => {
  return getOverridedElement(component, modulesOverrided);
};

module.exports = {
  componentNameResolver,
  schemaNameResolver
};
