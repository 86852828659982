<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      fill-rule="evenodd"
      d="M12,12 C12.6870207,12 13.3569236,12.0685729 14.0011957,12.1986052 L14,15 C14,15.0565551 14.0046948,15.1120136 14.0149527,15.1654654 C12.8419562,15.5730503 12,16.6882004 12,18 C12,18.5522847 12.4477153,19 13,19 C13.5522847,19 14,18.5522847 14,18 C14,17.4477153 14.4477153,17 15,17 C15.5522847,17 16,17.4477153 16,18 C16,18.5522847 16.4477153,19 17,19 C17.5522847,19 18,18.5522847 18,18 C18,16.6882004 17.1580438,15.5730503 15.986284,15.1660072 L15.9965249,15.0839642 L15.9965249,15.0839642 L16,15 L16.0009584,12.8316162 C18.9555435,14.1354651 21,16.840866 21,20 C21,20.5522847 20.5522847,21 20,21 L4,21 C3.44771525,21 3,20.5522847 3,20 C3,16.8405112 5.04491583,14.1348573 8.00003732,12.8311769 L8,15 C8,15.0862032 8.01090743,15.1698588 8.03224911,15.2493065 C7.41670831,15.590293 7,16.2464828 7,17 C7,18.1045695 7.8954305,19 9,19 C10.1045695,19 11,18.1045695 11,17 C11,16.2464828 10.5832917,15.590293 9.96858257,15.249662 C9.98909257,15.1698588 10,15.0862032 10,15 L10,15 L9.99980369,12.1984036 C10.6437691,12.068502 11.3133345,12 12,12 Z M12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>