import store from "@/store";

const getRemoteBuildVersion = req => req.headers["x-build-version"];

const getLocalBuildVersion = () => store.getters["versionManager/localBuildVersion"];

const setLocalBuildVersion = hash => store.dispatch("versionManager/setLocalBuildVersion", hash);

const updateApp = async req => {
  await setLocalBuildVersion(getRemoteBuildVersion(req));
  window.location.reload();
};

/**
 * Compare the local build version of the app with the build version sent from the server.
 * If versions aren't the same, update local build version and reload the page to rebuild
 * Vue app with updated files.
 *
 * @param {object} req - The request object.
 */
export const checkForBuildVersionChange = async req => {
  const remoteBuildVersion = getRemoteBuildVersion(req);
  const localBuildVersion = await getLocalBuildVersion();
  if (remoteBuildVersion && !localBuildVersion) {
    await setLocalBuildVersion(remoteBuildVersion);
  }
  if (remoteBuildVersion && localBuildVersion && localBuildVersion !== remoteBuildVersion) {
    await updateApp(req);
  }
};
