<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M17.7885822,8.37750756 L6.60125605,19.5648337 L4,20.0447694 L4.47993571,17.4435134 L15.6672619,6.25618721 L17.7885822,8.37750756 Z M17.48331,4.41421356 L19.6046304,6.53553391 L21.0188439,5.12132034 L18.8975236,3 L17.48331,4.41421356 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
