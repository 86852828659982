<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M3,8 L3,18 C3,18.5522847 3.44771525,19 4,19 L20,19 C20.5522847,19 21,18.5522847 21,18 L21,8 C21,7.44771525 20.5522847,7 20,7 L4,7 C3.44771525,7 3,7.44771525 3,8 Z M8,7 L8,5 C8,4.44771525 8.44771525,4 9,4 L15,4 C15.5522847,4 16,4.44771525 16,5 L16,7 L16,7 M10,15 L12.1899387,12.8100613 L14,11 M14,15 L11.8100613,12.8100613 L10,11"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconCancelRemoval",
  mixins: [iconMixin]
};
</script>
