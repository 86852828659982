import http from "@/services/http";

export const verifyUserToken = (type, token) => http.get(`/verify-token/${type}/${token}`);

export const registerUserCredentials = user => http.post(`/register/user`, user);

export const checkUsernameAvailability = params =>
  http.get("/users/username-availability", {
    params: {
      id: params.idToCheck,
      username: params.dataToCheck
    }
  });
