<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path 
      class="icon-filled"
      d="M16,3 C18.209139,3 20,4.790861 20,7 L20,19 C20,20.1045695 19.1045695,21 18,21 L15,21 C14.4477153,21 14,20.5522847 14,20 L14,17 C14,16.4477153 13.5522847,16 13,16 L11,16 C10.4477153,16 10,16.4477153 10,17 L10,20 C10,20.5522847 9.55228475,21 9,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L4,7 C4,4.790861 5.790861,3 8,3 L16,3 Z M9.5,12 L8.5,12 L8.38337887,12.0067277 C7.88604019,12.0644928 7.5,12.4871642 7.5,13 C7.5,13.5522847 7.94771525,14 8.5,14 L8.5,14 L9.5,14 L9.61662113,13.9932723 C10.1139598,13.9355072 10.5,13.5128358 10.5,13 C10.5,12.4477153 10.0522847,12 9.5,12 L9.5,12 Z M15.5,12 L14.5,12 L14.3833789,12.0067277 C13.8860402,12.0644928 13.5,12.4871642 13.5,13 C13.5,13.5522847 13.9477153,14 14.5,14 L14.5,14 L15.5,14 L15.6166211,13.9932723 C16.1139598,13.9355072 16.5,13.5128358 16.5,13 C16.5,12.4477153 16.0522847,12 15.5,12 L15.5,12 Z M9.5,9 L8.5,9 L8.38337887,9.00672773 C7.88604019,9.06449284 7.5,9.48716416 7.5,10 C7.5,10.5522847 7.94771525,11 8.5,11 L8.5,11 L9.5,11 L9.61662113,10.9932723 C10.1139598,10.9355072 10.5,10.5128358 10.5,10 C10.5,9.44771525 10.0522847,9 9.5,9 L9.5,9 Z M15.5,9 L14.5,9 L14.3833789,9.00672773 C13.8860402,9.06449284 13.5,9.48716416 13.5,10 C13.5,10.5522847 13.9477153,11 14.5,11 L14.5,11 L15.5,11 L15.6166211,10.9932723 C16.1139598,10.9355072 16.5,10.5128358 16.5,10 C16.5,9.44771525 16.0522847,9 15.5,9 L15.5,9 Z M9.5,6 L8.5,6 L8.38337887,6.00672773 C7.88604019,6.06449284 7.5,6.48716416 7.5,7 C7.5,7.55228475 7.94771525,8 8.5,8 L8.5,8 L9.5,8 L9.61662113,7.99327227 C10.1139598,7.93550716 10.5,7.51283584 10.5,7 C10.5,6.44771525 10.0522847,6 9.5,6 L9.5,6 Z M15.5,6 L14.5,6 L14.3833789,6.00672773 C13.8860402,6.06449284 13.5,6.48716416 13.5,7 C13.5,7.55228475 13.9477153,8 14.5,8 L14.5,8 L15.5,8 L15.6166211,7.99327227 C16.1139598,7.93550716 16.5,7.51283584 16.5,7 C16.5,6.44771525 16.0522847,6 15.5,6 L15.5,6 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
