import { fetchConversations, searchPatients, searchPrescribers } from "@/modules/common/_api";
import * as api from "../_api";

const state = {
  conversations: [],
  count: 0,
  countUnreadConversation: null,
  lastRequestId: null,
  formattedQuery: {}
};

const getters = {
  conversations: state => state.conversations,
  count: state => state.count,
  countUnreadConversation: state => state.countUnreadConversation,
  formattedQuery: state => state.formattedQuery
};

const mutations = {
  SET_CONVERSATIONS: (state, conversations) => {
    state.conversations = conversations;
  },
  SET_COUNT: (state, count) => {
    state.count = count;
  },
  SET_LAST_REQUEST_ID: (state, requestId) => {
    state.lastRequestId = requestId;
  },
  SET_UNREAD_COUNT: (state, countUnreadConversation) => {
    state.countUnreadConversation = countUnreadConversation;
  },
  SET_FORMATTED_QUERY: (state, formattedQuery) => {
    state.formattedQuery = formattedQuery;
  }
};

const actions = {
  fetchConversations(context, { requestId, query, ...params }) {
    context.dispatch("formatQuery", query);
    context.commit("SET_LAST_REQUEST_ID", requestId);
    return new Promise((resolve, reject) => {
      const { formattedQuery, lastRequestId } = context.state;
      fetchConversations({ ...params, query: formattedQuery })
        .then(res => {
          if (lastRequestId !== requestId) {
            resolve();
            return;
          }
          context.commit("SET_CONVERSATIONS", res.data.body.results);
          context.commit("SET_COUNT", res.data.body.count);
          resolve(true);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  formatQuery(context, query) {
    const { patients, prescribers, updateDate, ...formattedQuery } = query;
    if (patients && patients.length) {
      formattedQuery.patientIds = patients.map(item => item._id);
    }
    if (prescribers && prescribers.length) {
      formattedQuery.prescriberIds = prescribers.map(item => item._id);
    }
    if (updateDate) {
      formattedQuery.updateDateStart = updateDate.start || undefined;
      formattedQuery.updateDateEnd = updateDate.end || undefined;
    }
    context.commit("SET_FORMATTED_QUERY", formattedQuery);
  },
  getCountUnreadConversation(context) {
    api
      .getCountUnreadConversation()
      .then(res => {
        context.commit("SET_UNREAD_COUNT", res.data && res.data.body ? res.data.body.count : null);
      })
      .catch(() => {});
  },
  searchPatients(context, input) {
    return searchPatients({ query: { input }, skip: 0, limit: 10, source: "local" });
  },
  searchPrescribers(context, input) {
    return searchPrescribers({ query: { input }, skip: 0, limit: 10, source: "local" });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
