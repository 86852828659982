<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M11,5 C13.2225185,5 15.1627971,6.20841279 16.1996477,8.00405026 C17.9851471,8.09221576 19.4637072,9.35161986 19.8816838,11.030419 C21.0814266,11.2129375 22,12.2491966 22,13.5 C22,14.8807119 20.8807119,16 19.5,16 C18.9248936,16 18.3951432,15.8058076 17.9727435,15.4794174 C17.3918364,15.8108188 16.718011,16 16,16 C15.5249651,16 15.0692709,15.9171931 14.6465645,15.7652264 C13.6363343,16.5395365 12.3719406,17 11,17 C9.39787215,17 7.94240779,16.3720589 6.86629733,15.3488673 C6.3542375,15.7564285 5.70556334,16 5,16 C3.34314575,16 2,14.6568542 2,13 C2,11.3431458 3.34314575,10 5,10 C5.02790562,10 5.05572225,10.000381 5.08344498,10.0011381 C5.55776778,7.16339844 8.0262395,5 11,5 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
