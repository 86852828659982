<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <g class="icon-stroked">
      <g transform="translate(4, 4)">
        <path d="M12.2933668,9.14553625 C20.2936668,3.14551625 11.7929668,-4.35448375 6.29279679,3.14551625"></path>
        <line x1="4.27343679" y1="10.9990362" x2="11.2734668" y2="3.99903625"></line>
        <path d="M3.27302679,5.99903625 C-4.72729321,11.9990362 3.77343679,19.4990362 9.27356679,11.9990362"></path>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
