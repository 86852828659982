<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 14"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <line x1="4.974" x2="22.026" y1="1" y2="1" class="icon-stroked" />
    <line x1="4.974" x2="22.026" y1="7" y2="7" class="icon-stroked" />
    <line x1="4.974" x2="22.026" y1="13" y2="13" class="icon-stroked" />
    <circle cx="1" cy="1" r="1" class="icon-filled" />
    <circle cx="1" cy="7" r="1" class="icon-filled" />
    <circle cx="1" cy="13" r="1" class="icon-filled" />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
