<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M7,2 L17,2 C17.5522847,2 18,2.44771525 18,3 L18,21 C18,21.5522847 17.5522847,22 17,22 L7,22 C6.44771525,22 6,21.5522847 6,21 L6,3 C6,2.44771525 6.44771525,2 7,2 Z M7,3.11111111 L17,3.11111111 C17.5522847,3.11111111 18,3.55882636 18,4.11111111 L18,19.8888889 C18,20.4411736 17.5522847,20.8888889 17,20.8888889 L7,20.8888889 C6.44771525,20.8888889 6,20.4411736 6,19.8888889 L6,4.11111111 C6,3.55882636 6.44771525,3.11111111 7,3.11111111 Z M9,6.44444444 L14,6.44444444 M9,9.22222222 L15,9.22222222 M9,12 L12,12"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMailOutlined",
  mixins: [iconMixin]
};
</script>
