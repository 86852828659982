<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 11 15"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M22.55,47 L27.07,47 C30.31,47 32.21,45.08 32.21,42.78 C32.21,41.3 31.41,40.18 30.03,39.56 C30.97,38.98 31.49,38.08 31.49,36.86 C31.49,34.62 29.71,32.78 26.79,32.78 C25.33,32.78 23.97,33.2 22.55,33.72 L22.55,47 Z M25.43,38.64 L25.43,35.42 C25.87,35.26 26.23,35.18 26.75,35.18 C27.95,35.18 28.53,35.88 28.53,36.86 C28.53,37.82 27.93,38.64 26.73,38.64 L25.43,38.64 Z M25.43,44.6 L25.43,40.86 L26.97,40.86 C28.55,40.86 29.19,41.66 29.19,42.78 C29.19,43.86 28.35,44.6 26.97,44.6 L25.43,44.6 Z"
      transform="translate(-22 -32)"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
