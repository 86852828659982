/* eslint-disable prefer-destructuring */
export const formatOrdoclicPatientName = patient => {
  return new Promise((resolve, reject) => {
    let firstName = patient.firstname || "";
    let lastName = patient.lastname || "";

    // missing all
    if (!firstName && !lastName) {
      return reject("Merci de vous assurer que le patient dispose d'un nom et d'un prénom pour pouvoir signer électroniquement ses prescriptions");
    }

    // all exist
    if (firstName && lastName) {
      return resolve({
        firstName,
        lastName
      });
    }

    // need to split
    let splitName = "";

    if (!lastName) {
      splitName = firstName.trim().split(" ");

      if (!splitName || !splitName.length || splitName.length < 2) {
        return reject("Merci de vous assurer que le patient dispose d'un nom et d'un prénom pour pouvoir signer électroniquement ses prescriptions");
      }

      firstName = splitName[0];

      for (let i = 1; i < splitName.length; i += 1) {
        lastName += `${splitName[i]} `;
      }
    }

    if (!firstName) {
      splitName = lastName.trim().split(" ");

      if (!splitName || !splitName.length || splitName.length < 2) {
        return reject("Merci de vous assurer que le patient dispose d'un nom et d'un prénom pour pouvoir signer électroniquement ses prescriptions");
      }

      firstName = splitName[0];

      for (let i = 1; i < splitName.length; i += 1) {
        lastName += `${splitName[i]} `;
      }
    }

    return resolve({
      firstName,
      lastName
    });
  });
};
