<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path class="icon-stroked" d="M3,5 L8,3 L16,7 L21,5 L21,19 L16,21 L8,17 L3,19 L3,5 Z M8,17 L8,3 M16,21 L16,7" />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
