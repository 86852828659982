<template functional>
  <svg
    viewBox="0 0 16 15"
    xmlns="http://www.w3.org/2000/svg"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <g stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g transform="translate(-844.000000, -411.000000)" stroke-width="2">
        <g transform="translate(96.000000, 296.000000)">
          <g transform="translate(8.000000, 16.000000)">
            <g transform="translate(714.000000, 63.000000)">
              <g transform="translate(16.000000, 24.000000)">
                <g transform="translate(0.000000, 1.000000)">
                  <g transform="translate(10.000000, 12.000000)">
                    <line x1="1.12147736" y1="12.4534029" x2="14.2566187" y2="0"></line>
                    <polyline points="4.61538462 0 14.2566187 0 14.2566187 9.41984083"></polyline>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconArrowUp",
  mixins: [iconMixin]
};
</script>
