<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <title v-if="props.tooltipText">{{ props.tooltipText }}</title>
    <path
      class="icon-stroked"
      d="M12.6066017,2.70710678 L21.0918831,11.1923882 C21.4824074,11.5829124 21.4824074,12.2160774 21.0918831,12.6066017 L12.6066017,21.0918831 C12.2160774,21.4824074 11.5829124,21.4824074 11.1923882,21.0918831 L2.70710678,12.6066017 C2.31658249,12.2160774 2.31658249,11.5829124 2.70710678,11.1923882 L11.1923882,2.70710678 C11.5829124,2.31658249 12.2160774,2.31658249 12.6066017,2.70710678 Z M11.8994949,16.8994949 L11.8994949,15.8994949 M11.8994949,12.8994949 L11.8994949,6.89949494"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
