import { createPrescriptionAddress } from "@/modules/common/_api";

const state = {
  prescriptionAddresses: null
};

const getters = {
  prescriptionAddresses: state => state.prescriptionAddresses
};

const mutations = {
  UPDATE_EQUIPEMENT_INFORMATIONS_LOADING: (state, loadingEquipementInformations) => {
    state.loadingEquipementInformations = loadingEquipementInformations;
  }
};

const actions = {
  createPrescriptionAddress: (context, prescriptionAddressData) => {
    return new Promise((resolve, reject) => {
      createPrescriptionAddress(prescriptionAddressData)
        .then(response => {
          return resolve(response?.data?.body || {});
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions };
