const state = {
  feedbackMessages: {}
};

const getters = {
  getFeedbackMessages: state => state.feedbackMessages,
  getFeedbackMessage: state => key => state.feedbackMessages[key] || null
};

const mutations = {
  SET_FEEDBACK_MESSAGES: (state, feedbackMessages) => {
    state.feedbackMessages = feedbackMessages;
  },
  RESET_SPECIFIC_MESSAGE: (state, feedbackMessagesKey) => {
    if (state.feedbackMessages[feedbackMessagesKey]) {
      delete state.feedbackMessages[feedbackMessagesKey];
    }
  },
  RESET_ALL_MESSAGES: state => {
    state.feedbackMessages = {};
  }
};

const actions = {
  resetFeedbackMessages(context, messageKey = null) {
    if (messageKey) {
      context.commit("RESET_SPECIFIC_MESSAGE", messageKey);
    } else {
      context.commit("RESET_ALL_MESSAGES");
    }
  },
  updateFeedbackMessage(context, { key, message, type }) {
    const updatedFeedbackMessages = { ...context.state.feedbackMessages };

    updatedFeedbackMessages[key] = { message, type };

    context.commit("SET_FEEDBACK_MESSAGES", updatedFeedbackMessages);
  }
};

export default { state, getters, mutations, actions };
