<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M16.8,6 L19,6 C19.5522847,6 20,6.33578644 20,6.75 L20,17.25 C20,17.6642136 19.5522847,18 19,18 L16.8,18 L16.8,18 L13.6,18 M7.2,18 L5,18 C4.44771525,18 4,17.6642136 4,17.25 L4,6.75 C4,6.33578644 4.44771525,6 5,6 L7.2,6 L7.2,6 L10.4,6 M9,3 L11.6667981,5.23333697 C12.0902144,5.58793121 12.1460057,6.21863338 11.7914115,6.64204968 C11.7536219,6.68717376 11.7119222,6.72887342 11.6667981,6.76666303 L9,9 L9,9 M15,15 L12.3332019,17.233337 C11.9097856,17.5879312 11.8539943,18.2186334 12.2085885,18.6420497 C12.2463781,18.6871738 12.2880778,18.7288734 12.3332019,18.766663 L15,21 L15,21"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
