<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12 15"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M88.9,41.86 L88.9,33.04 L90.8,33.04 L90.8,42.22 C90.8,43.94 91.74,45.64 94,45.64 C96.24,45.64 97.22,43.94 97.22,42.22 L97.22,33.04 L99.1,33.04 L99.1,41.86 C99.1,45.28 97.28,47.26 94,47.26 C90.76,47.26 88.9,45.28 88.9,41.86 Z"
      transform="translate(-88 -33)"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
