<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M11,18.5 C11,19.6045695 10.1045695,20.5 9,20.5 C7.8954305,20.5 7,19.6045695 7,18.5 L7,18.5 Z M9,5.5 C16.4509247,5.5 12.8489806,15.5 16,17.5 L16,17.5 L2,17.5 C5.15080019,15.5 1.54907531,5.5 9,5.5 Z M9,3.5 C9.55228475,3.5 10,3.94771525 10,4.5 L10,4.5 L8,4.5 C8,3.94771525 8.44771525,3.5 9,3.5 Z M20,8 L20,12 M18,10 L22,10"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
