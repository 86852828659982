<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M19,4 L21,4 C22.1045695,4 23,4.8954305 23,6 L23,19 C23,20.1045695 22.1045695,21 21,21 L19,21 C17.8954305,21 17,20.1045695 17,19 L17,6 C17,4.8954305 17.8954305,4 19,4 Z M5,13 C6.1045695,13 7,13.8954305 7,15 L7,19 C7,20.1045695 6.1045695,21 5,21 L3,21 C1.8954305,21 1,20.1045695 1,19 L1,15 C1,13.8954305 1.8954305,13 3,13 L5,13 Z M13,9 C14.1045695,9 15,9.8954305 15,11 L15,19 C15,20.1045695 14.1045695,21 13,21 L11,21 C9.8954305,21 9,20.1045695 9,19 L9,11 C9,9.8954305 9.8954305,9 11,9 L13,9 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
