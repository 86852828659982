<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M11.2824856,8.1299603 L13.2824856,13.1261517 L8.28248558,20.1261517 L3.28248558,13.1261517 L5.28248558,8.1299603 L11.2824856,8.1299603 Z M11.2824856,5.12259832 L11.2824856,8.12259832 L5.28248558,8.12259832 L5.28248558,5.12259832 L11.2824856,5.12259832 Z M8.28248558,19.1261517 C8.28248558,15.230709 8.28248558,13.2829876 8.28248558,13.2829876 M8.62604614,23.1347084 C5.985022,25.2499187 7.85370253,30.437116 11.3860931,25.8947554 C14.9184837,21.3523948 14.5052159,17.2178658 17.5910353,14.1320464 C20.6768547,11.046227 24.2196209,13.7311467 21.7843121,15.4965364"
      transform="rotate(45 13.208 16.756)"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
