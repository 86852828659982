/**
 * This mapping prevents from using delivery's
 * status id directly for business logic
 *
 * waiting => delivery has been created but nothing has been sent to SAP
 * pendingPreparation => delivery is waiting to be transfer to buffer stock (only for Diabete workflow)
 * pendingDelivery => delivery is waiting to be delivered from buffer stock (only for Diabete workflow)
 * delivered => delivery has been delivery to patient
 * canceled => delivery has been canceled or the refused the delivery
 * toBeRemoved => delivery will be removed from patient contract once the intervention will be achieved
 * removed => delivery has been removed from patient
 * archived => delivery has been deleted before something has been sent to SAP
 */
module.exports = {
  waiting: "waiting",
  pendingPreparation: "pendingPreparation",
  pendingDelivery: "pendingDelivery",
  delivered: "delivered",
  canceled: "canceled",
  toBeRemoved: "toBeRemoved",
  removed: "removed",
  archived: "archived"
};
