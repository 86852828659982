const _ = require("lodash");
const { locationTypeIds } = require("../../constants/locationTypes");
const { getLocationTypes } = require("../getLocationTypes");
const mergePatientLocations = require("../patient/mergePatientLocations");

const formatPatientLocations = patient => {
  const patientLocations = mergePatientLocations(patient);
  for (const patientLocation of patientLocations) {
    patientLocation.type = locationTypeIds.inPatient;
  }
  return patientLocations;
};

const formatPrescriberLocations = prescriptionAddress => {
  if (_.isEmpty(prescriptionAddress?.location)) {
    return null;
  }
  return {
    ...prescriptionAddress.location,
    type: locationTypeIds.prescriberVisit
  };
};

const formatPatientCommunityLocations = patient => {
  if (_.isEmpty(patient?.community?.locations)) {
    return null;
  }
  const communityLocations = patient.community.locations;
  for (const communityLocation of communityLocations) {
    communityLocation.type = locationTypeIds.inCommunity;
  }
  return communityLocations;
};

const extractInterventionLocations = (patient, contractId, platform) => {
  if (_.isEmpty(patient) || _.isEmpty(contractId)) {
    return [];
  }
  const contract = patient.contracts?.find(_contract => _contract.id === contractId) || {};
  if (_.isEmpty(contract)) {
    return [];
  }

  const interventionLocations = [];

  getLocationTypes(platform).forEach(locationType => {
    switch (locationType._id) {
      // PATIENT LOCATIONS
      case locationTypeIds.inPatient: {
        const patientLocations = formatPatientLocations(patient);
        if (patientLocations?.length) {
          interventionLocations.push(...patientLocations);
        }
        break;
      }
      // POLE LOCATION
      case locationTypeIds.poleVisit: {
        const poleLocation = { street: locationType.label, type: locationTypeIds.poleVisit };
        interventionLocations.push(poleLocation);
        break;
      }
      // PRESCRIBER LOCATION
      case locationTypeIds.prescriberVisit: {
        const prescriberLocation = formatPrescriberLocations(contract.prescriptionAddress);
        if (prescriberLocation) {
          interventionLocations.push(prescriberLocation);
        }
        break;
      }
      // COMMUNITY LOCATIONS
      case locationTypeIds.inCommunity: {
        const communityLocations = formatPatientCommunityLocations(patient);
        if (communityLocations?.length) {
          interventionLocations.push(...communityLocations);
        }
        break;
      }
      default: {
        break;
      }
    }
  });

  return interventionLocations;
};

module.exports = extractInterventionLocations;
