<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M10.83,18.31l2.34,2.33m-2.34,0,2.34-2.33M20.49,6.87A12.26,12.26,0,0,0,18.1,5M15,3.72A12,12,0,0,0,3.51,6.87m11.32,7.51a4,4,0,0,0-5.66,0m8.49-3.83a8,8,0,0,0-6.53-2.3m-2.87.88a8.16,8.16,0,0,0-1.92,1.42"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMedical",
  mixins: [iconMixin]
};
</script>
