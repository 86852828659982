/* eslint-disable no-unused-vars */

export default (deliveries = []) => {
  if (!deliveries || !Array.isArray(deliveries) || !deliveries.length) {
    return null;
  }

  const filtredDeliveries = [];

  deliveries.forEach(delivery => {
    const { product, status, ...cleanedDelivery } = delivery;

    if (cleanedDelivery) {
      filtredDeliveries.push(cleanedDelivery);
    }
  });

  return filtredDeliveries;
};
