<template functional>
  <svg :width="props.width" :height="props.height" :class="`icon-${props.color}`" viewBox="0 0 24 24">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g id="add--actu-icon" transform="translate(1, 2)" class="icon-stroked">
        <path
          d="M4,5 L16,5 C16.5522847,5 17,5.44771525 17,6 L17,15 C17,15.5522847 16.5522847,16 16,16 L4,16 C3.44771525,16 3,15.5522847 3,15 L3,6 C3,5.44771525 3.44771525,5 4,5 Z M7,10 C7.55228475,10 8,9.55228475 8,9 C8,8.44771525 7.55228475,8 7,8 C6.44771525,8 6,8.44771525 6,9 C6,9.55228475 6.44771525,10 7,10 Z M4,15 L7.2,12.5 L9.6,14 L12.8,9 L16,15 M0,8 L0,17 C1.3527075e-16,18.1045695 0.8954305,19 2,19 L16,19 L16,19"
          id="ic--color"
        ></path>
        <g id="Group" transform="translate(18, 0)">
          <line x1="2" y1="0" x2="2" y2="4" id="Path-23-Copy"></line>
          <line x1="0" y1="2" x2="4" y2="2" id="Path-24-Copy"></line>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconProductNotice",
  mixins: [iconMixin]
};
</script>
