import Contact from "@/views/Contact";
import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.footer";
const platform = __PLATFORM__;
const target = __TARGET__;

function setComponent(componentName) {
  return () => import(`@/views/legal/${componentName}/${target}/${platform}`);
}

export default [
  {
    path: "contact",
    name: "contact",
    component: Contact,
    meta: {
      title: localize(localPath, "title--contact"),
      public: true
    }
  },
  {
    path: "legal-notice",
    name: "mentions-legales-cgu",
    component: setComponent("LegalNoticesTOS"),
    meta: {
      title: localize(localPath, "title--legalNotice"),
      public: true
    }
  },
  {
    path: "personal-data",
    name: "donnees-personnelles",
    component: setComponent("PersonalData"),
    meta: {
      title: localize(localPath, "title--personalData"),
      public: true
    }
  }
];
