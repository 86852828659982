import http from "@/services/http";

/**
 * @param {Object} params
 * * @param {String} manufacturer
 * * @param {String} contractId
 * * @param {String} startDate Start date with expected format YYYY-MM-DD
 * * @param {String} endDate End date with expected format YYYY-MM-DD
 * @returns {Promise<String>} A promise that returns :
 *    - On success, a base64 string of the report.
 *    - On failure, an error or nothing if response is empty.
 */
const getTelemonitoringReport = params => {
  return new Promise((resolve, reject) => {
    http
      .get("/telesuivi/report", { params, timeout: 300000 })
      .then(res => {
        if (!res?.data?.body) {
          reject();
          return;
        }

        resolve(res.data.body);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export default getTelemonitoringReport;
