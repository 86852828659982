import {
  createQuestionnaire,
  getQuestionnairesForInterventionDisplay,
  getQuestionnairesForPatientDisplay,
  getQuestionnairesForExtranetDisplay
} from "../_api";

const formatDataToSave = dataToSave => {
  const dataFormatted = dataToSave;

  // Used to clean label for db
  if (dataFormatted.results) {
    dataFormatted.results = dataFormatted.results.map(({ key, value }) => {
      return { key, value };
    });
  }

  return dataToSave;
};

const state = {
  questionnaires: {}
};

const getters = {
  questionnaires: state => {
    return state.questionnaires;
  }
};

const mutations = {
  SET_QUESTIONNAIRES: (state, questionnaires) => {
    state.questionnaires = questionnaires;
  }
};

const actions = {
  createQuestionnaire(context, dataToSave) {
    const dataFormatted = formatDataToSave(dataToSave);

    return new Promise((resolve, reject) => {
      createQuestionnaire(dataFormatted)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchQuestionnairesForIntervention(context, interventionId) {
    return new Promise((resolve, reject) => {
      getQuestionnairesForInterventionDisplay(interventionId)
        .then(res => {
          context.commit("SET_QUESTIONNAIRES", res.data?.body || {});
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchQuestionnairesForPatient(context, patientId) {
    return new Promise((resolve, reject) => {
      getQuestionnairesForPatientDisplay(patientId)
        .then(res => {
          context.commit("SET_QUESTIONNAIRES", res.data?.body || {});
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchQuestionnairesForExtranet(context, patientId) {
    return new Promise((resolve, reject) => {
      getQuestionnairesForExtranetDisplay(patientId)
        .then(res => {
          context.commit("SET_QUESTIONNAIRES", res.data?.body || {});
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions };
