const getTreatmentsFromForfaits = forfaits => {
  if (!forfaits || !Array.isArray(forfaits)) {
    return [];
  }

  const treatments = forfaits.map(forfait => forfait.treatments);
  if (!treatments.length) {
    return [];
  }

  return [].concat(...treatments).filter(treatment => treatment);
};

module.exports = getTreatmentsFromForfaits;
