import moment from "moment";
import { USER, PATIENT, PRESCRIBER, PRESCRIBERS_GROUP } from "@common/constants/entityTypes";

const features = require("@/services/features");

export default formData => {
  // TODO:  Revoir ce fichier qui devient compliqué à lire. Peut-être qu'il faudrait plutôt formater le message côté serveur après avoir récupéré les participants de la conversation
  const { targetedPatient, patient, prescriber, user, files } = formData;
  const date = moment().toISOString();

  const message = {
    content: formData.content,
    sentDate: date,
    sender: {},
    targets: []
  };

  const conversation = {
    typeId: formData.typeId,
    subjectContent: formData.subjectContent,
    participants: [],
    updateDate: date
  };

  // A prescriber always create a conversation for a user. Users are added on the server side according to the prescriber's pole
  if (__TARGET__ === "prescriber") {
    const type = prescriber.isRegroupementPrescripteurs ? PRESCRIBERS_GROUP : PRESCRIBER;
    message.sender = { id: prescriber._id, type };
    conversation.poleId = prescriber.poleId;
    if (targetedPatient) {
      conversation.subject = { id: targetedPatient._id, type: PATIENT };
    }

    if (patient) {
      conversation.participants.push({ id: patient._id, type: PATIENT, read: false });
    }

    if (prescriber) {
      conversation.participants.push({ id: prescriber._id, type, read: true });
    }
  }

  // A patient always create a conversation for a user. Users are added on the server side according to the patient's pole
  if (__TARGET__ === "extranet") {
    message.sender = { id: patient._id, type: PATIENT };
    const [poleId] = patient.account.poleIds;
    conversation.typeId = features.isSelectMessageTypeOnExtranetEnabled() ? formData.typeId : "patientExtranet";
    conversation.poleId = poleId;
    conversation.participants.push({ id: patient._id, type: PATIENT, read: true });
    conversation.subject = { id: patient._id, type: PATIENT };
  }

  // A user can create a conversation for a prescriber or a patient, depends of the feature activated. Both cannot be actived at the same time
  if (__TARGET__ === "admin") {
    message.sender = { id: user._id, type: USER };

    if (prescriber) {
      conversation.poleId = prescriber.poleId;
    } else {
      // eslint-disable-next-line prefer-destructuring
      conversation.poleId = patient.account.poleIds[0];
    }

    if (prescriber) {
      conversation.participants.push({ id: prescriber._id, type: PRESCRIBER });
      message.targets.push({ id: prescriber._id, type: PRESCRIBER, read: false });
    }

    if (targetedPatient) {
      conversation.subject = { id: targetedPatient._id, type: PATIENT };
    }

    if (patient) {
      conversation.participants.push({ id: patient._id, type: PATIENT, read: false });
      message.targets.push({ id: patient._id, type: PATIENT, read: false });
    }

    conversation.participants.push({ id: user._id, type: USER, read: true });
  }

  if (files && files.length) {
    message.files = files;
  }

  return { conversation, message };
};
