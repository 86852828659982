<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
    transform="translate(3.000000, 2.000000)"
  >
    <path
      d="M19,4.67557456 C19,4.58150722 18.9741379,4.48743987 18.9482759,4.43613041 L18.8793103,4.27365045 C18.8448276,4.21378942 18.8017241,4.15392838 18.7413793,4.09406734 L18.6982759,4.05130946 C18.6982759,4.05130946 18.6551724,4.01710315 18.6293103,4 L9.87931034,0.0833778728 C9.63793103,-0.0277926243 9.36206897,-0.0277926243 9.11206897,0.0833778728 L0.612068966,3.87172635 C0.560344828,3.8888295 0.517241379,3.91448423 0.50862069,3.93158739 L0.387931034,4 C0.301724138,4.05986104 0.224137931,4.1453768 0.163793103,4.23944415 C0.129310345,4.29075361 0.103448276,4.34206307 0.0775862069,4.4019241 C0.0517241379,4.47033672 0.0344827586,4.53874933 0.025862069,4.60716195 C0.0172413793,4.64136825 0,4.68412614 0,4.74398717 L0,15.1940139 C0,15.5531801 0.215517241,15.8866916 0.543103448,16.04062 L9.12068966,19.9230358 C9.12068966,19.9230358 9.18965517,19.9486905 9.19827586,19.940139 C9.25862069,19.9657937 9.32758621,19.9743453 9.37931034,19.9828968 C9.40517241,19.9828968 9.44827586,20 9.49137931,20 C9.60344828,20 9.71551724,19.9743453 9.8362069,19.9230358 L18.4568966,16.0320684 C18.7931034,15.87814 19,15.5531801 19,15.1854623 L19,4.67557456 L19,4.67557456 Z M15.75,4.71833244 L9.48275862,7.42063068 L7.32758621,6.48850882 L13.4396552,3.69214324 L15.75,4.71833244 Z M3.23275862,4.71833244 L9.49137931,1.93051844 L11.1724138,2.67450561 L5.02586207,5.48797435 L3.23275862,4.70978087 L3.23275862,4.71833244 Z M8.55172414,17.6226617 L1.85344828,14.586852 L1.85344828,6.14644575 L8.52586207,9.01977552 L8.55172414,17.6141101 L8.55172414,17.6226617 Z M17.1293103,6.14644575 L17.1293103,14.586852 L10.4310345,17.6226617 L10.4568966,9.05398183 L17.1293103,6.14644575 Z"
      id="Shape"
    ></path>
    <path
      d="M7.49850864,11.7816455 L5.52723489,9.20831332 L5.50827246,9.18815396 C5.45138515,9.13439569 5.38185623,9.09407698 5.28704406,9.05375828 C5.26808163,9.04703849 5.24911919,9.04031871 5.21119432,9.03359892 C5.1416654,9.01343957 5.07845729,9.00671978 5.00260755,9 C4.97732431,9 4.95204106,9 4.92675782,9 C4.84458727,9.00671978 4.76873753,9.02687914 4.70552942,9.05375828 L4.66128374,9.06719784 C4.59175482,9.10079677 4.52222589,9.14783526 4.46533859,9.20831332 L2.53117033,11.0831332 C2.20880896,11.3922432 2.18352571,11.929826 2.48060384,12.272535 C2.6259825,12.4405296 2.81560684,12.5346066 3.03051443,12.5480462 C3.2391012,12.554766 3.44136716,12.4808483 3.59938744,12.3262933 L4.21250614,11.7349523 L4.21250614,13.977979 C4.21250614,14.4416442 4.56647157,14.8179521 5.00260755,14.8179521 C5.43874353,14.8179521 5.79270896,14.4416442 5.79270896,13.977979 L5.79270896,11.7349523 L6.49831771,12.9710473 C6.71271191,13.2469917 7.27095943,13.3003168 7.55539594,12.9710473 C7.7007746,12.8030527 7.77030353,12.5880196 7.76398272,12.3662667 C7.75134109,12.1445138 7.66284973,11.9362005 7.50482945,11.7883653 L7.49850864,11.7816455 Z"
      id="Path-Copy"
    ></path>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconProductSample",
  mixins: [iconMixin]
};
</script>
