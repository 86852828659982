<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M6,9.8722316 L18,9.8722316 C18.5522847,9.8722316 19,10.3199469 19,10.8722316 L19,19 C19,19.5522847 18.5522847,20 18,20 L6,20 C5.44771525,20 5,19.5522847 5,19 L5,10.8722316 C5,10.3199469 5.44771525,9.8722316 6,9.8722316 Z M13,13.923339 C13.5522847,13.923339 14,14.3710542 14,14.923339 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,14.923339 C10,14.3710542 10.4477153,13.923339 11,13.923339 L13,13.923339 Z M12.5595779,4.17338894 L21,9.8722316 L3,9.8722316 L11.4404221,4.17338894 C11.7785464,3.94509259 12.2214536,3.94509259 12.5595779,4.17338894 Z M16,4.00216668 L17,4.00216668 L17,7.15 L16,6.5 L16,4.00216668 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
