/**
 * Make a full queries with all posibilities for ean with multiples 0 in first and second place.
 * @param {String} ean The complete ean scanned
 * @return {Array} An array with all the possibilities for the provided ean
 */

const makeQueriesForDifferentsEanPattern = ean => {
  const queries = [];

  queries.push(ean);

  if (ean.at(0) === "0") {
    queries.push(ean.slice(1));
  }

  if (ean.at(0) === "0" && ean.at(1) === "0") {
    queries.push(ean.slice(2));
  }

  if (ean.length === 12) {
    queries.push("00".concat(ean));
    queries.push("0".concat(ean));
  }

  if (ean.length === 13) {
    queries.push("0".concat(ean));
  }

  return queries;
};

module.exports = makeQueriesForDifferentsEanPattern;
