export default (poles = []) => {
  if (!poles || !Array.isArray(poles) || !poles.length) return null;

  return poles.map(pole => {
    if (!pole) return null;

    return {
      ...pole,
      label: `${pole.label} (${pole._id})`,
      value: pole._id,
      divisionIds: pole.divisionIds
    };
  });
};
