const capitalize = require("lodash/capitalize");

/**
 * Format string to display resource's name
 *
 * @param {Object} resource
 * * @param {String} [resource.firstname]
 * * @param {String} [resource.lastname]
 * * @param {String} [resource.label]
 * * @param {String} [resource.name]
 * @returns {String} The formatted resource name
 */
module.exports = resource => {
  if (!resource) {
    return null;
  }

  const { firstname, lastname, label, name } = resource;

  if (firstname && lastname) {
    return `${lastname.toUpperCase()} ${capitalize(firstname)}`;
  }

  if (name && name.replace(/\s/g, "")) {
    return name;
  }

  if (firstname && !lastname) {
    return `${capitalize(firstname)}`;
  }

  if (!firstname && lastname) {
    return `${lastname.toUpperCase()}`;
  }

  if (label && label.replace(/\s/g, "")) {
    return label;
  }

  return null;
};
