<template functional>
  <svg :width="props.width" :height="props.height" viewBox="0 0 24 24" :class="`icon-${props.color}`">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <path
        d="M12,10.5 C13.2426407,10.5 14.25,9.49264069 14.25,8.25 C14.25,7.00735931 13.2426407,6 12,6 C10.7573593,6 9.75,7.00735931 9.75,8.25 C9.75,9.49264069 10.7573593,10.5 12,10.5 Z M18,18 C18,15.1005051 15.3137085,12.75 12,12.75 C8.6862915,12.75 6,15.1005051 6,18 M2,16 C2,14.0670034 3.790861,12.5 6,12.5 C7.10731253,12.5 8.10953555,12.893699 8.83381478,13.5298494 M6,11 C5.17157288,11 4.5,10.3284271 4.5,9.5 C4.5,8.67157288 5.17157288,8 6,8 C6.82842712,8 7.5,8.67157288 7.5,9.5 C7.5,10.3284271 6.82842712,11 6,11 Z M20,7 L20,11 M18,9 L22,9"
        class="icon-stroked"
      ></path>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
