<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M12,4 C12.5522847,4 13,4.44771525 13,5 L13,17 L8.73239368,16.9999275 C8.38657394,16.4021661 7.74025244,16 7,16 C6.25974756,16 5.61342606,16.4021661 5.26760632,16.9999275 L4,17 L4,5 C4,4.44771525 4.44771525,4 5,4 L12,4 Z M7,20 C8.1045695,20 9,19.1045695 9,18 C9,16.8954305 8.1045695,16 7,16 C5.8954305,16 5,16.8954305 5,18 C5,19.1045695 5.8954305,20 7,20 Z M17,20 C18.1045695,20 19,19.1045695 19,18 C19,16.8954305 18.1045695,16 17,16 C15.8954305,16 15,16.8954305 15,18 C15,19.1045695 15.8954305,20 17,20 Z M20,13 L20,17 L18.7323937,16.9999275 C18.3865739,16.4021661 17.7402524,16 17,16 C16.2597476,16 15.6134261,16.4021661 15.2676063,16.9999275 L13,17 L13,13 L20,13 Z M13,8 L16.4338096,8 C16.7850725,8 17.1105793,8.18429883 17.2913025,8.48550424 L20,13 L20,13"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
