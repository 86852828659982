<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M4,4.5 L19.5,4.5 C20.0522847,4.5 20.5,4.94771525 20.5,5.5 L20.5,21 C20.5,21.5522847 20.0522847,22 19.5,22 L4,22 C3.44771525,22 3,21.5522847 3,21 L3,5.5 C3,4.94771525 3.44771525,4.5 4,4.5 Z M6.75,4.5 L6.75,2 M16.75,4.5 L16.75,2 M3,9.5 L20.5,9.5 M11.75,13.25 L11.75,13.875 M15.5,13.25 L15.5,13.875 M8,13.25 L8,13.875 M11.75,17 L11.75,17.625 M15.5,17 L15.5,17.625 M8,17 L8,17.625"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
