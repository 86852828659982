<template functional>
  <svg
    viewBox="0 0 18 9"
    xmlns="http://www.w3.org/2000/svg"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <g stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g transform="translate(-1139.000000, -645.000000)" stroke-width="2">
        <g transform="translate(96.000000, 296.000000)">
          <g transform="translate(8.000000, 16.000000)">
            <g transform="translate(1009.000000, 294.000000)">
              <g transform="translate(16.000000, 25.000000)">
                <g transform="translate(11.121477, 14.822403)">
                  <g transform="translate(0.000000, 6.000000)">
                    <line x1="0" y1="0.631391649" x2="15" y2="0.631391649"></line>
                  </g>
                  <g>
                    <line x1="0" y1="0.631391649" x2="15" y2="0.631391649"></line>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconEqual",
  mixins: [iconMixin]
};
</script>
