<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M3,5 L18.0244141,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,18 C22,18.5522847 21.5522847,19 21,19 L3,19 C2.44771525,19 2,18.5522847 2,18 L2,6 C2,5.44771525 2.44771525,5 3,5 Z M12,16.6666667 C14.5773288,16.6666667 16.6666667,14.5773288 16.6666667,12 C16.6666667,9.42267117 14.5773288,7.33333333 12,7.33333333 C9.42267117,7.33333333 7.33333333,9.42267117 7.33333333,12 C7.33333333,14.5773288 9.42267117,16.6666667 12,16.6666667 Z M8.70016835,15.2998316 L15.2998316,8.70016835"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
