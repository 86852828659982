/** The clickOutside directive triggers a method when a click is detected outside element.
 * Usage: <div v-click-outside="closeEvent">
 * */

// Need to be external function to remove event listeners
const stopProp = event => {
  event.stopPropagation();
};

const requestAnimFrame =
  window.requestAnimationFrame ||
  /* If requestAnimationFrame is not supported, fallback to setTimeout at 60 fps */
  function(callback) {
    window.setTimeout(callback, 1000 / 60);
  };

export const clickOutside = {
  bind: (el, binding) => {
    if (typeof binding.value === "function") {
      el.addEventListener("mousedown", stopProp);
      /* Use requestAnimationFrame to make sure the mousedown event triggering a creation of a bound component
         has time to propagate before triggering the eventListener
      */
      requestAnimFrame(() => {
        document.body.addEventListener("mousedown", binding.value);
      });
    }
  },
  unbind: (el, binding) => {
    el.removeEventListener("mousedown", stopProp);
    document.body.removeEventListener("mousedown", binding.value);
  }
};
