<template functional>
  <svg :width="props.width" :height="props.height" :class="`icon-${props.color}`" viewBox="0 0 24 24">
    <g>
      <path
        class="icon-stroked"
        d="M21,2 L21,6 M19,4 L23,4 M2,7 L2,18 C2,18.5522847 2.44771525,19 3,19 L17,19 C17.5522847,19 18,18.5522847 18,18 L18,7 L18,7 M3,18 L8,13 M17,18 L12,13 M18,7 L10,14 L2,7 L18,7 Z"
      ></path>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
