<template functional>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :class="`icon-${props.color}`"
  >
    <g
      class="icon-stroked"
      id="outlined-/-action-/-reminders"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g id="Group" transform="translate(3, 2)" stroke="inherit" stroke-width="1.5">
        <circle id="Oval" cx="8.57142857" cy="11.4285714" r="8.57142857"></circle>
        <line x1="0.476190476" y1="3.33333333" x2="4.28571429" y2="0.476190476" id="Line"></line>
        <line x1="16.1904762" y1="2.85714286" x2="12.8571429" y2="0.476190476" id="Line-Copy"></line>
        <polyline id="Path-35" points="8.08042194 7.67762977 8.08042194 11.7437964 10.5842882 14.4322194"></polyline>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconClock",
  mixins: [iconMixin]
};
</script>
