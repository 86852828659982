import { login, activateAccount, checkEmailAvailability } from "../_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  authTempUser(context, data) {
    return new Promise((resolve, reject) => {
      login(data)
        .then(response => resolve(response))
        .catch(err => reject(err));
    });
  },
  checkEmailAvailability(context, data) {
    return new Promise((resolve, reject) => {
      checkEmailAvailability(data)
        .then(response => resolve(response))
        .catch(err => reject(err));
    });
  },
  activateAccount(context, data) {
    return new Promise((resolve, reject) => {
      activateAccount(data)
        .then(response => {
          resolve(response.data.body);
        })
        .catch(err => reject(err));
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
