/* eslint-disable no-param-reassign */
import store from "@/store";
import { getLocalUser } from "@/services/auth";

const { acm, getUserRolesChecks } = require("@common/services/acm");

/**
 *
 * @param {Object} user
 * @returns {Boolean} Return if current can sign renew documents
 */
const userCanSign = user => Boolean(user.renewalInfos && user.renewalInfos.canRenew && user.renewalInfos.ordoclicId);

const AcmPluginFactory = () => {
  return getLocalUser().then(user => {
    return {
      install(Vue) {
        if (user) {
          Object.assign(user, { ...acm(user), ...getUserRolesChecks(user), canSign: userCanSign(user) });
          Vue.prototype.$user = user;
          store.dispatch("login/setUser", user);

          /* Set $user both on Vue prototype to use in components and on store to use in actions */
        } else {
          Vue.prototype.$user = {
            isLoggedIn: false
          };
          store.dispatch("login/unsetUser");
        }
      }
    };
  });
};

export default AcmPluginFactory;
