<template functional>
  <svg
    :width="props.width"
    :height="props.height"
    viewBox="0 0 26.75 26.7520048"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    :class="`icon-${props.color}`"
  >
    <title v-if="props.tooltipText">{{ props.tooltipText }}</title>
    <g id="BACK-|-CMS-App-Médecin" stroke="none" stroke-width="1" fill="none">
      <g id="front__home-bandeau" transform="translate(-54.125000, -262.125000)">
        <g id="messaging-copy-2" transform="translate(40.000000, 251.000000)">
          <g id="outlined-/-alert-/-error" transform="translate(15.125000, 12.125000)">
            <path
              class="icon-stroked"
              d="M23.75,0 L1,0 C0.44771525,-2.06356265e-15 0,0.44771525 0,1 L0,18.25 C3.55271368e-15,18.8022847 0.44771525,19.25 1,19.25 L19.25,19.25 L19.25,19.25 L24.75,24.75 L24.75,1 C24.75,0.44771525 24.3022847,1.01453063e-16 23.75,0 Z"
              id="ic--color--border"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="translate(12.375000, 12.375000) scale(-1, 1) translate(-12.375000, -12.375000) "
            ></path>
            <path
              class="icon-filled"
              d="M12.375,11.6875 C13.5140873,11.6875 14.4375,10.7640873 14.4375,9.625 C14.4375,8.4859127 13.5140873,7.5625 12.375,7.5625 C11.2359127,7.5625 10.3125,8.4859127 10.3125,9.625 C10.3125,10.7640873 11.2359127,11.6875 12.375,11.6875 Z M6.875,11.6875 C8.0140873,11.6875 8.9375,10.7640873 8.9375,9.625 C8.9375,8.4859127 8.0140873,7.5625 6.875,7.5625 C5.7359127,7.5625 4.8125,8.4859127 4.8125,9.625 C4.8125,10.7640873 5.7359127,11.6875 6.875,11.6875 Z M17.875,11.6875 C19.0140873,11.6875 19.9375,10.7640873 19.9375,9.625 C19.9375,8.4859127 19.0140873,7.5625 17.875,7.5625 C16.7359127,7.5625 15.8125,8.4859127 15.8125,9.625 C15.8125,10.7640873 16.7359127,11.6875 17.875,11.6875 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
