<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M20,20 C20,16.1340068 16.418278,13 12,13 C7.581722,13 4,16.1340068 4,20 M12,10 C13.6568542,10 15,8.65685425 15,7 C15,5.34314575 13.6568542,4 12,4 C10.3431458,4 9,5.34314575 9,7 C9,8.65685425 10.3431458,10 12,10 Z M13,17.3718165 C13,17.7856096 13.4137931,18.302851 14.2413793,18.9235406 C15.0689655,18.302851 15.4827586,17.7856096 15.4827586,17.3718165 C15.4827586,16.440782 14.2413793,17.440782 14.2413793,18.3718165 C14.2413793,17.440782 13,16.440782 13,17.3718165 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
