<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M20,12.7741935 C20,11.919043 19.2836556,11.2258065 18.4,11.2258065 C17.5163444,11.2258065 16.8,11.919043 16.8,12.7741935 L16.8,13.2903226 M16.8,13.2903226 L16.8,15.3548387 C16.8,16.2099893 16.0836556,16.9032258 15.2,16.9032258 C14.3163444,16.9032258 13.6,16.2099893 13.6,15.3548387 M13.6,15.3548387 L13.6,5.5483871 C13.6,4.69323652 12.8836556,4 12,4 C11.1163444,4 10.4,4.69323652 10.4,5.5483871 L10.4,6.06451613 M10.4,6.06451613 L10.4,18.4516129 C10.4,19.3067635 9.6836556,20 8.8,20 C7.9163444,20 7.2,19.3067635 7.2,18.4516129 M7.2,18.4516129 L7.2,10.7096774 C7.2,9.85452684 6.4836556,9.16129032 5.6,9.16129032 C4.7163444,9.16129032 4,9.85452684 4,10.7096774"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
