<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M21,5 C21.5522847,5 22,5.44771525 22,6 L22,19 C22,19.5522847 21.5522847,20 21,20 L19,20 C18.4477153,20 18,19.5522847 18,19 L18,6 C18,5.44771525 18.4477153,5 19,5 L21,5 Z M5,14 C5.55228475,14 6,14.4477153 6,15 L6,19 C6,19.5522847 5.55228475,20 5,20 L3,20 C2.44771525,20 2,19.5522847 2,19 L2,15 C2,14.4477153 2.44771525,14 3,14 L5,14 Z M13,10 C13.5522847,10 14,10.4477153 14,11 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,11 C10,10.4477153 10.4477153,10 11,10 L13,10 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
