import commonStore from "./index.common";
import * as api from "../_api";
import formatNewConversationData from "@/helpers/_functions/conversations/formatNewConversationData";

const state = {
  ...commonStore.state
};

const getters = {
  ...commonStore.getters
};

const mutations = {
  ...commonStore.mutations
};

const actions = {
  ...commonStore.actions,
  addConversation(context, data) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line no-param-reassign
      data.patient = context.rootGetters["login/$user"];
      const { conversation, message } = formatNewConversationData(data);

      api
        .createConversationPatient(conversation, message)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
