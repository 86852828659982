import * as api from "../_api";

const state = {
  patientOrders: []
};

const getters = {
  patientOrders: state => state.patientOrders
};

const mutations = {
  SET_PATIENT_ORDERS: (state, patientOrders) => {
    state.patientOrders = patientOrders;
  }
};

const actions = {
  fetchPatientOrders(context, patientId) {
    return new Promise((resolve, reject) => {
      api
        .fetchPatientOrders(patientId)
        .then(response => {
          context.commit("SET_PATIENT_ORDERS", response.data.body);
          resolve(response.data.body);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  createPatientOrder(context, { contractId, patientId, supplyDeliveries }) {
    return new Promise((resolve, reject) => {
      api
        .createPatientOrder({ contractId, patientId, supplyDeliveries })
        .then(response => {
          context
            .dispatch("fetchPatientOrders", patientId)
            .then(() => {
              resolve(response.data);
            })
            .catch(error => {
              reject(error);
            });
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
