const entityTypes = {
  PRESCRIBER: "prescribers",
  USER: "users",
  PATIENT: "patients",
  ORGANISME: "organismes",
  COMMUNITY: "communities",
  COMMUNITIES_GROUP: "communitiesGroups",
  PRESCRIBERS_GROUP: "prescribersGroups",
  PLATFORM: "platform"
};

module.exports = entityTypes;
