<template functional>
  <svg :width="props.width" :height="props.height" viewBox="0 0 24 24" :class="`icon-${props.color}`">
    <g
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      transform="translate(0, 0)"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M20,8 L20,12 M18,10 L22,10 M15,19 C15,17.8954305 14.1045695,17 13,17 C11.8954305,17 11,17.8954305 11,19 M10,10 C11.6568542,10 13,8.65685425 13,7 C13,5.34314575 11.6568542,4 10,4 C8.34314575,4 7,5.34314575 7,7 C7,8.65685425 8.34314575,10 10,10 Z M13,17 L13,14 M7,17 L7,14 M7,19 C7.55228475,19 8,18.5522847 8,18 C8,17.4477153 7.55228475,17 7,17 C6.44771525,17 6,17.4477153 6,18 C6,18.5522847 6.44771525,19 7,19 Z M18,20 C18,16.1340068 14.418278,13 10,13 C5.581722,13 2,16.1340068 2,20"
        class="icon-stroked"
      ></path>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
