<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M20,15 C20.5128358,15 20.9355072,15.3860402 20.9932723,15.8833789 L21,16 L21,19 C21,20.0543618 20.1841222,20.9181651 19.1492623,20.9945143 L19,21 L16,21 C15.4477153,21 15,20.5522847 15,20 C15,19.4871642 15.3860402,19.0644928 15.8833789,19.0067277 L16,19 L19,19 L19,16 C19,15.4477153 19.4477153,15 20,15 Z M4,15 C4.51283584,15 4.93550716,15.3860402 4.99327227,15.8833789 L5,16 L5,19 L8,19 C8.55228475,19 9,19.4477153 9,20 C9,20.5128358 8.61395981,20.9355072 8.11662113,20.9932723 L8,21 L5,21 C3.9456382,21 3.08183488,20.1841222 3.00548574,19.1492623 L3,19 L3,16 C3,15.4477153 3.44771525,15 4,15 Z M9,8.5 C9.51283584,8.5 9.93550716,8.88604019 9.99327227,9.38337887 L10,9.5 L10,14.5 C10,15.0522847 9.55228475,15.5 9,15.5 C8.48716416,15.5 8.06449284,15.1139598 8.00672773,14.6166211 L8,14.5 L8,9.5 C8,8.94771525 8.44771525,8.5 9,8.5 Z M12,8.5 C12.5128358,8.5 12.9355072,8.88604019 12.9932723,9.38337887 L13,9.5 L13,14.5 C13,15.0522847 12.5522847,15.5 12,15.5 C11.4871642,15.5 11.0644928,15.1139598 11.0067277,14.6166211 L11,14.5 L11,9.5 C11,8.94771525 11.4477153,8.5 12,8.5 Z M15,8.5 C15.5128358,8.5 15.9355072,8.88604019 15.9932723,9.38337887 L16,9.5 L16,14.5 C16,15.0522847 15.5522847,15.5 15,15.5 C14.4871642,15.5 14.0644928,15.1139598 14.0067277,14.6166211 L14,14.5 L14,9.5 C14,8.94771525 14.4477153,8.5 15,8.5 Z M19,3 C20.0543618,3 20.9181651,3.81587779 20.9945143,4.85073766 L21,5 L21,8 C21,8.55228475 20.5522847,9 20,9 C19.4871642,9 19.0644928,8.61395981 19.0067277,8.11662113 L19,8 L19,5 L16,5 C15.4477153,5 15,4.55228475 15,4 C15,3.48716416 15.3860402,3.06449284 15.8833789,3.00672773 L16,3 L19,3 Z M8,3 C8.55228475,3 9,3.44771525 9,4 C9,4.51283584 8.61395981,4.93550716 8.11662113,4.99327227 L8,5 L5,5 L5,8 C5,8.55228475 4.55228475,9 4,9 C3.48716416,9 3.06449284,8.61395981 3.00672773,8.11662113 L3,8 L3,5 C3,3.9456382 3.81587779,3.08183488 4.85073766,3.00548574 L5,3 L8,3 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
