/**
 * get Country label from country code
 * @param {String} countryCode
 * @param {Object} taxonomies
 * @returns {String}
 */

// FIXME: Idéalement, trouver un moyen d'utiliser les taxonomies
const getCountryLabelFromCountryCode = countryCode => {
  if (!countryCode) {
    return "";
  }

  switch (countryCode) {
    case "FR":
      return "France";
    case "BE":
      return "Belgique";
    case "GB":
      return "United Kingdom";
    case "CA":
      return "Canada";
    default:
      return "";
  }
};

module.exports = getCountryLabelFromCountryCode;
