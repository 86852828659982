<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      d="M5.78880244,11 C4.09317958,11 2.72805948,10.4436319 1.74373604,9.34430225 C-0.842807302,6.46191347 0.198994878,0.817794028 0.249288777,0.576477757 C0.306767518,0.25472273 0.608530908,0.026812919 0.953403354,0.026812919 L3.08011677,0.026812919 C3.47528312,0.026812919 3.79860103,0.328458257 3.79860103,0.697135893 C3.79860103,1.06581353 3.47528312,1.36745887 3.08011677,1.36745887 L1.57848466,1.36745887 C1.3988636,2.78184034 1.11146989,6.54905545 2.85020181,8.48628885 C3.56150123,9.27726996 4.52427014,9.65935405 5.78880244,9.65935405 C7.29043455,9.65935405 8.40408516,9.22364412 9.19441784,8.3187081 C10.9331498,6.34125533 10.5954622,2.70810481 10.3942866,1.34064595 L9.07946036,1.34064595 C8.68429402,1.34064595 8.3609761,1.03900061 8.3609761,0.670322974 C8.3609761,0.301645338 8.68429402,0 9.07946036,0 L11.0049982,0 C11.3498706,0 11.6444492,0.227909811 11.7091128,0.536258379 C11.7594067,0.76416819 12.8946118,6.21389397 10.3080685,9.16331505 C9.24471174,10.3765996 7.72152511,10.9932968 5.78880244,10.9932968 L5.78880244,11 Z"
    />
    <path
      d="M8.91750228,21 C6.02552416,21 5,18.7451338 5,16.8114355 L5,10.7226277 C5,10.3546229 5.30765725,10.053528 5.68368277,10.053528 C6.0597083,10.053528 6.36736554,10.3546229 6.36736554,10.7226277 L6.36736554,16.8114355 C6.36736554,17.6678832 6.61349134,19.6618005 8.91750228,19.6618005 C10.8933455,19.6618005 11.8983592,18.5377129 11.8983592,16.3296837 L11.8983592,16.1088808 C11.8983592,14.3625304 11.8983592,12.1946472 13.1631723,10.9434307 C13.8058341,10.3144769 14.6877849,10 15.7885141,10 C20,10 20,14.1015815 20,17.3935523 C20,17.7615572 19.6923428,18.0626521 19.3163172,18.0626521 C18.9402917,18.0626521 18.6326345,17.7615572 18.6326345,17.3935523 C18.6326345,13.2785888 18.3523245,11.3381995 15.7885141,11.3381995 C15.0501367,11.3381995 14.5100273,11.5188564 14.1340018,11.8868613 C13.2657247,12.736618 13.2657247,14.6034063 13.2657247,16.1021898 L13.2657247,16.3229927 C13.2657247,19.2937956 11.6795807,20.993309 8.91750228,20.993309 L8.91750228,21 Z"
    />
    <path
      d="M19.5,22 C18.1242604,22 17,20.8757396 17,19.5 C17,18.1242604 18.1242604,17 19.5,17 C20.8757396,17 22,18.1242604 22,19.5 C22,20.8757396 20.8757396,22 19.5,22 Z M19.5,18.4792899 C18.9378698,18.4792899 18.4792899,18.9378698 18.4792899,19.5 C18.4792899,20.0621302 18.9378698,20.5207101 19.5,20.5207101 C20.0621302,20.5207101 20.5207101,20.0621302 20.5207101,19.5 C20.5207101,18.9378698 20.0621302,18.4792899 19.5,18.4792899 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
