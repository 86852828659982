<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M18,12 C18,7.581722 14.418278,4 10,4 C5.581722,4 2,7.581722 2,12 L2,12 C2,16.418278 5.581722,20 10,20 C12.2804037,20 14.3379575,19.0458662 15.7952242,17.5150359 M21,11 L18.766663,13.6667981 C18.4120688,14.0902144 17.7813666,14.1460057 17.3579503,13.7914115 C17.3128262,13.7536219 17.2711266,13.7119222 17.233337,13.6667981 L15,11 L15,11"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
