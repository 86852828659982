<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <title v-if="props.tooltipText">{{ props.tooltipText }}</title>
    <path
      class="icon-stroked"
      d="M8,11 C8.55228475,11 9,10.5522847 9,10 C9,9.44771525 8.55228475,9 8,9 C7.44771525,9 7,9.44771525 7,10 C7,10.5522847 7.44771525,11 8,11 Z M3,6 L3,11.7671707 C3,12.0323872 3.10535684,12.2867411 3.29289322,12.4742775 L9.4742775,18.6556618 C9.86480179,19.0461861 10.4979668,19.0461861 10.8884911,18.6556618 L16.6556618,12.8884911 C17.0461861,12.4979668 17.0461861,11.8648018 16.6556618,11.4742775 L10.4742775,5.29289322 C10.2867411,5.10535684 10.0323872,5 9.76717072,5 L4,5 C3.44771525,5 3,5.44771525 3,6 Z M15,19 L20.5857864,13.4142136 C21.366835,12.633165 21.366835,11.366835 20.5857864,10.5857864 L15,5 L15,5"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
