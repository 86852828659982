import http from "../../../services/http";

/**
 * @param {Object} params
 * @returns {Array<Object>} prescriptionAddresses
 */
export default ({ query, skip, limit, source }) =>
  http.get("/prescription-addresses/search", {
    params: { query, skip, limit, source }
  });
