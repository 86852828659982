import i18n from "@/i18n";

export default function handleFeedbackMessages(response) {
  const i18nKey = "feedbackMessages";
  if (response?.data?.success) {
    // eslint-disable-next-line no-param-reassign
    response.data.successMessage = i18n.t(`${i18nKey}.${response.data.success.module}.success.${response.data.success.key}`, {
      param: response.data.success.param || null
    });
  }

  if (response?.response?.data?.error) {
    // eslint-disable-next-line no-param-reassign
    response.response.data.errorMessage = i18n.t(`${i18nKey}.${response.response.data.error.module}.errors.${response.response.data.error.key}`, {
      param: response.response.data.error.param || null
    });
  } else if (response?.data?.error) {
    // eslint-disable-next-line no-param-reassign
    response.data.errorMessage = i18n.t(`${i18nKey}.${response.data.error.module}.errors.${response.data.error.key}`, {
      param: response.data.error.param || null
    });
  }
}
