<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(2.000000, 2.000000)">
        <circle class="icon-stroked" stroke-width="1.5" stroke-linejoin="round" cx="10" cy="10" r="10"></circle>
        <path
          d="M6,16 C6,16 6,15.3828571 6,14.08 L6,5.92 C6,4.6 6,4 6,4 L9.90534208,4 C12.6888472,4 14.6626054,4.73714286 14.6626054,6.96571429 C14.6626054,8.37142857 13.7179007,9.22857143 12.0477976,9.55428571 C13.9372071,9.93142857 15,11.1142857 15,12.5885714 C15,14.8171429 13.0262418,16 10.1921275,16 L6,16 Z M8.49342105,5 C8.18092105,5 8,5.17120623 8,5.46692607 L8,9 L10.3190789,9 C11.9802632,9 13,8.42412451 13,6.9922179 C13,5.56031128 12.0789474,5 10.2203947,5 L8.49342105,5 L8.49342105,5 Z M8,10 L8,14.5145631 C8,14.8220065 8.16975309,15 8.46296296,15 L10.2993827,15 C12.1512346,15 13,13.9967638 13,12.5080906 C13,11.0194175 12.058642,10 10.4845679,10 L8,10 L8,10 Z"
          fill-rule="nonzero"
          class="icon-filled"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
