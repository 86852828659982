<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M4.82372721,17.9949073 C3.24891996,17.9036609 2,16.5976809 2,15 C2,13.6811736 2.85100045,12.561109 4.03383105,12.1589765 C4.01119904,11.942773 4,11.7226943 4,11.5 C4,7.91014913 6.91014913,5 10.5,5 C12.8478488,5 14.9049569,6.2448089 16.0473408,8.11044314 C18.3058008,8.5910781 20,10.5976883 20,13 C20,13.0164851 19.9999202,13.0329516 19.999761,13.0493991 C21.1408321,13.2813059 22,14.2903182 22,15.5 C22,16.7093254 21.1413382,17.7180997 20.0004345,17.9499027 L20,18 L5,18 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
