<template>
  <svg class="logo-app">
    <use :xlink:href="logoPath" />
  </svg>
</template>

<script>
const logoAppSmall = import(`@ui/images/${__DEMO__ ? "demo" : __PLATFORM__}/logo_small.svg`);

export default {
  name: "LogoAppSmall",
  data() {
    return {
      logoPath: ""
    };
  },
  mounted() {
    logoAppSmall.then(logo => {
      this.logoPath = `${logo.default}#logo_app`;
    });
  }
};
</script>
