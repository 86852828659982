/* eslint-disable import/no-cycle */
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import router from "@/router";
import settings from "../../settings/bastide";

export const initErrorReporting = () => {
  Sentry.init({
    Vue,
    dsn: settings.sentryDsn,
    tunnel: "/api/sentry",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router)
      })
    ],
    tracingOptions: {
      trackComponents: true
    },
    attachStacktrace: true,
    tracesSampleRate: 1,
    ignoreErrors: ["Non-Error exception captured", "Non-Error promise rejection captured"]
  });
};
