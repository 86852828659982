<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M14,18.5 C14,19.6045695 13.1045695,20.5 12,20.5 C10.8954305,20.5 10,19.6045695 10,18.5 L10,18.5 Z M12,5.5 C19.4509247,5.5 15.8489806,15.5 19,17.5 L19,17.5 L5,17.5 C8.15080019,15.5 4.54907531,5.5 12,5.5 Z M12,3.5 C12.5522847,3.5 13,3.94771525 13,4.5 L13,4.5 L11,4.5 C11,3.94771525 11.4477153,3.5 12,3.5 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
