<template functional>
  <svg :width="props.width" :height="props.height" :class="`icon-${props.color}`" viewBox="0 0 24 24">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="add--bandeau-icon" transform="translate(3, 2)">
        <g id="Group-2" transform="translate(0, 5)">
          <path
            class="icon-stroked"
            d="M13,0 L1,0 C0.44771525,-1.01453063e-16 0,0.44771525 0,1 L0,9.88888889 C-1.77635684e-15,10.4411736 0.44771525,10.8888889 1,10.8888889 L10.8888889,10.8888889 L10.8888889,10.8888889 L14,14 L14,1 C14,0.44771525 13.5522847,-9.5692398e-18 13,0 Z"
            transform="translate(7, 7) scale(-1, 1) translate(-7, -7)"
          ></path>
          <path
            class="icon-filled"
            d="M7,6.5 C7.55228475,6.5 8,6.05228475 8,5.5 C8,4.94771525 7.55228475,4.5 7,4.5 C6.44771525,4.5 6,4.94771525 6,5.5 C6,6.05228475 6.44771525,6.5 7,6.5 Z M4,6.5 C4.55228475,6.5 5,6.05228475 5,5.5 C5,4.94771525 4.55228475,4.5 4,4.5 C3.44771525,4.5 3,4.94771525 3,5.5 C3,6.05228475 3.44771525,6.5 4,6.5 Z M10,6.5 C10.5522847,6.5 11,6.05228475 11,5.5 C11,4.94771525 10.5522847,4.5 10,4.5 C9.44771525,4.5 9,4.94771525 9,5.5 C9,6.05228475 9.44771525,6.5 10,6.5 Z"
          ></path>
        </g>
        <g transform="translate(16, 0)" class="icon-stroked">
          <line x1="2" y1="0" x2="2" y2="4" id="Path-23-Copy"></line>
          <line x1="0" y1="2" x2="4" y2="2" id="Path-24-Copy"></line>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconProductNotice",
  mixins: [iconMixin]
};
</script>
