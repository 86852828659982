import http from "@/services/http";

/**
 * @param {Object} params
 * @returns {Array<Object>} zones
 */
// export default params => http.get("/zones", { params });

export default params =>
  new Promise((resolve, reject) => {
    /* resolve({ data: { body: { results: [], count: 0 } } }); */
    http
      .get("/zones", {
        params
      })
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
