<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path class="icon-stroked" d="M20,3 L4,3 C3.44771525,3 3,3.44771525 3,4 L3,16 C3,16.5522847 3.44771525,17 4,17 L17,17 L17,17 L21,21 L21,4 C21,3.44771525 20.5522847,3 20,3 Z" />
    <path class="icon-filled" d="M12,8.5 C12.8284271,8.5 13.5,9.17157288 13.5,10 C13.5,10.8284271 12.8284271,11.5 12,11.5 C11.1715729,11.5 10.5,10.8284271 10.5,10 C10.5,9.17157288 11.1715729,8.5 12,8.5 Z M8,8.5 C8.82842712,8.5 9.5,9.17157288 9.5,10 C9.5,10.8284271 8.82842712,11.5 8,11.5 C7.17157288,11.5 6.5,10.8284271 6.5,10 C6.5,9.17157288 7.17157288,8.5 8,8.5 Z M16,8.5 C16.8284271,8.5 17.5,9.17157288 17.5,10 C17.5,10.8284271 16.8284271,11.5 16,11.5 C15.1715729,11.5 14.5,10.8284271 14.5,10 C14.5,9.17157288 15.1715729,8.5 16,8.5 Z" />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
