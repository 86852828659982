import store from "@/store";

const switchLocale = (locale, path) => {
  localStorage.setItem("user-locale", locale);
  const url = new URL(path, window.location.origin);
  window.location.replace(url.href);
};

export default function i18nMiddleware({ next, to }) {
  const { locale } = to.params;
  const defaultLocale = store.getters["appInfos/defaultLocale"];
  const supportedLocales = store.getters["appInfos/supportedLocales"];

  const currentLocale = localStorage.getItem("user-locale");
  if (!currentLocale || !supportedLocales.includes(currentLocale)) {
    switchLocale(defaultLocale, to.fullPath);
    return;
  }

  const localeChanged = locale && locale !== currentLocale;
  if (localeChanged) {
    if (supportedLocales.includes(locale)) {
      switchLocale(locale, to.fullPath);
      return;
    }
    return next({ name: "notFound" });
  }

  return next();
}
