import http from "../../../services/http";

/**
 * @param {String} stockId
 * @param {Object} params
 * @returns {Promise<[Object]>}
 */
const fetchStockContentWithoutDetails = (stockId, params) =>
  http.get(`/stocks/${stockId}/no-details`, {
    params,
    timeout: 300000
  });
export default fetchStockContentWithoutDetails;
