import http from "../../../services/http";

/**
 * @param {Object} params
 * @returns {Array<Object>} products
 */
export default params =>
  http.get("/products/search", {
    params
  });
