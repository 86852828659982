import i18n from "@/i18n";

export default [
  {
    title: i18n.t("footer.link--connection"),
    slug: "connexion",
    routerLink: { name: "login" }
  },
  {
    title: i18n.t("footer.link--legalNotice"),
    slug: "mentions-legales-cgu",
    routerLink: { name: "mentions-legales-cgu" }
  },
  {
    title: i18n.t("footer.link--personalData"),
    slug: "donnees-personnelles",
    routerLink: { name: "donnees-personnelles" }
  }
];
