<template>
  <div class="flex--row--flex--end text-align--right">
    <p class="margin--bot--null ft--body--m--second--font ft--weight--bold">{{ $t(`components.text.today-date.p`) }}</p>
    <h2 class="margin--top--null">{{ this.date }}</h2>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "TodayDate",
  data() {
    return {
      date: moment().locale(this.$i18n.locale).format("DD MMMM YYYY")
    };
  }
};
</script>
