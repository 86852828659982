<template functional>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <polygon
      class="icon-stroked"
      points="12 18 6.12214748 21.0901699 7.24471742 14.545085 2.48943484 9.90983006 9.06107374 8.95491503 12 3 14.9389263 8.95491503 21.5105652 9.90983006 16.7552826 14.545085 17.8778525 21.0901699"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
