<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <polygon
      :opacity="$options.getOpacity(props.direction, 1)"
      class="icon-stroked icon-filled"
      points="7 10 12 3 17 10"
    />
    <polygon
      :opacity="$options.getOpacity(props.direction, -1)"
      class="icon-stroked icon-filled"
      points="7 21 12 14 17 21"
      transform="rotate(180 12 17.5)"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin],
  props: {
    direction: {
      type: Number
    }
  },
  getOpacity(direction, value) {
    if (!direction) {
      return 1;
    }
    return direction === value ? 1 : 0.35;
  }
};
</script>
