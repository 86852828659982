export default {
  props: {
    width: {
      type: String,
      required: false,
      default: "24"
    },
    height: {
      type: String,
      required: false,
      default: "24"
    },
    color: {
      type: String,
      required: false,
      default: "nc-60"
    },
    additionalColor: {
      type: String,
      required: false,
      default: "nc-25"
    },
    tooltipText: {
      type: String
    }
  }
};
