<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M4,9.5 L4,19.9285714 C4,20.4808562 4.44771525,20.9285714 5,20.9285714 L19,20.9285714 C19.5522847,20.9285714 20,20.4808562 20,19.9285714 L20,9.5 L20,9.5 M12.6507914,3.20067832 L20,9.5 L20,9.5 L12.6507914,15.7993217 C12.2763016,16.1203129 11.7236984,16.1203129 11.3492086,15.7993217 L4,9.5 L4,9.5 L11.3492086,3.20067832 C11.7236984,2.87968712 12.2763016,2.87968712 12.6507914,3.20067832 Z M5.14285714,19.7857143 L9.71428571,15.2142857 M18.8571429,19.7857143 L14.2857143,15.2142857"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMailOutlined",
  mixins: [iconMixin]
};
</script>
