<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M20,3 L8,3 C7.44771525,3 7,3.44771525 7,4 L7,13 C7,13.5522847 7.44771525,14 8,14 L17.7692308,14 L17.7692308,14 L21,17 L21,14 L21,4 C21,3.44771525 20.5522847,3 20,3 Z M4,7 L7,7 L7,7 L7,13 C7,13.5522847 7.44771525,14 8,14 L17,14 L17,14 L17,17 C17,17.5522847 16.5522847,18 16,18 L6.23076923,18 L6.23076923,18 L3,21 L3,18 L3,8 C3,7.44771525 3.44771525,7 4,7 Z M11,7 L17,7 M11,10 L17,10"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
