import { localize } from "../_functions/i18n/dictionaryPathResolver";

const localPath = "constants.default-error-messages";

export default {
  add: localize(localPath, `add`),
  archive: localize(localPath, `archive`),
  ban: localize(localPath, `ban`),
  unban: localize(localPath, `unban`),
  create: localize(localPath, `create`),
  default: localize(localPath, `default`),
  downloadFile: localize(localPath, `downloadFile`),
  fileDoesNotExist: localize(localPath, `fileDoesNotExist`),
  export: localize(localPath, `export`),
  fetch: localize(localPath, `fetch`),
  form: localize(localPath, `form`),
  unarchive: localize(localPath, `unarchive`),
  update: localize(localPath, `update`),
  delete: localize(localPath, `delete`),
  search: localize(localPath, `search`),
  notFound: localize(localPath, `notFound`),
  unauthorized: localize(localPath, `unauthorized`),
  server: localize(localPath, `server`),
  riskAnalysisNoExist: localize(localPath, `riskAnalysisNoExist`),
  riskAnalysisRights: localize(localPath, `riskAnalysisRights`),
  synchronize: localize(localPath, `synchronize`),
  stockMovment: localize(localPath, `stockMovment`),
  searchStock: localize(localPath, `searchStock`),
  createDeliveries: localize(localPath, `createDeliveries`),
  removeDelivery: localize(localPath, `removeDelivery`),
  maxPatientNameLength: maxPatientNameLength => localize(localPath, `maxPatientNameLength`, { maxPatientNameLength }),
  unknownSAP: serialNumber => localize(localPath, `unknownSAP`, { serialNumber }),
  updateEntityDelivery: localize(localPath, `updateEntityDelivery`),
  uploadSignatureAndSignBL: localize(localPath, `uploadSignatureAndSignBL`),
  uploadSignature: localize(localPath, `uploadSignature`),
  fetchByQuery: localize(localPath, `fetchByQuery`),
  removeAppairage: localize(localPath, `removeAppairage`),
  unknownAGA: agaNumber => localize(localPath, `unknownAGA`, { agaNumber }),
  updateLegacyConsommables: localize(localPath, `updateLegacyConsommables`),
  updatePreUseChecklistOfDelivery: localize(localPath, `updatePreUseChecklistOfDelivery`),
  searchInStocks: localize(localPath, `searchInStocks`),
  equipementAvailabilityCaution: localize(localPath, `equipementAvailabilityCaution`),
  cancelDeliveryRemoval: label => localize(localPath, `cancelDeliveryRemoval`, { label }),
  checkPlanningSlotAvailability: localize(localPath, `checkPlanningSlotAvailability`),
  signatureError: localize(localPath, `signatureError`)
};
