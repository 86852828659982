/**
 * Extract values for each taxonomies and follow a label/value format
 *
 * @param {Array} taxonomies
 * @returns {Object}
 */
export default (taxonomies = []) => {
  if (!taxonomies || !taxonomies.length || !Array.isArray(taxonomies)) return [];

  return taxonomies.reduce((acc, taxonomy) => {
    const values = taxonomy.values.map(value => {
      const item = { label: value.label, value: value.key };
      if (value.parent) {
        item.parent = value.parent;
      }
      return item;
    });
    acc[taxonomy._id] = values;
    return acc;
  }, {});
};
