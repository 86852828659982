<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M3,5 L21,5 C21.5522847,5 22,5.44771525 22,6 L22,18 C22,18.5522847 21.5522847,19 21,19 L3,19 C2.44771525,19 2,18.5522847 2,18 L2,6 C2,5.44771525 2.44771525,5 3,5 Z M14,10.5 L19,10.5 M14,13.5 L17,13.5"
    />
    <path
      class="icon-filled"
      d="M4.5,4.5 C6.35365038,4.5 7.875,5.83118092 7.875,7.5 C7.875,7.70710678 7.70710678,7.875 7.5,7.875 L1.5,7.875 C1.29289322,7.875 1.125,7.70710678 1.125,7.5 C1.125,5.83118092 2.64634962,4.5 4.5,4.5 Z M4.5,1.125 C5.32842712,1.125 6,1.79657288 6,2.625 C6,3.45342712 5.32842712,4.125 4.5,4.125 C3.67157288,4.125 3,3.45342712 3,2.625 C3,1.79657288 3.67157288,1.125 4.5,1.125 Z"
      transform="translate(3 7.5)"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
