<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M3,8 L3,18 C3,18.5522847 3.44771525,19 4,19 L20,19 C20.5522847,19 21,18.5522847 21,18 L21,8 C21,7.44771525 20.5522847,7 20,7 L4,7 C3.44771525,7 3,7.44771525 3,8 Z M8,7 L8,5 C8,4.44771525 8.44771525,4 9,4 L15,4 C15.5522847,4 16,4.44771525 16,5 L16,7 L16,7"
    />
    <path
      class="icon-filled"
      d="M10.3333333,11.3333333 L10.3333333,10 C10.3333333,9.44771525 10.7810486,9 11.3333333,9 L12.6666667,9 C13.2189514,9 13.6666667,9.44771525 13.6666667,10 L13.6666667,11.3333333 L15,11.3333333 C15.5522847,11.3333333 16,11.7810486 16,12.3333333 L16,13.6666667 C16,14.2189514 15.5522847,14.6666667 15,14.6666667 L13.6666667,14.6666667 L13.6666667,16 C13.6666667,16.5522847 13.2189514,17 12.6666667,17 L11.3333333,17 C10.7810486,17 10.3333333,16.5522847 10.3333333,16 L10.3333333,14.6666667 L9,14.6666667 C8.44771525,14.6666667 8,14.2189514 8,13.6666667 L8,12.3333333 C8,11.7810486 8.44771525,11.3333333 9,11.3333333 L10.3333333,11.3333333 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMedicalDevice",
  mixins: [iconMixin]
};
</script>
