<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <g
      class="icon-stroked"
      id="outlined-/-action-/-new-collectivity-group"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <line x1="20" y1="3" x2="20" y2="7" id="Path-23-Copy" stroke-width="2"></line>
      <line x1="18" y1="5" x2="22" y2="5" id="Path-24-Copy" stroke-width="2"></line>
      <g id="Plan-de-travail-1-Copy" transform="translate(2, 6)">
        <g id="uuid-3095fef0-8874-495f-bc32-5607b7cbd335" transform="translate(6.04, 2.78)" stroke-width="1.62">
          <path
            d="M2.43,0 L8.9,0 C10.24,0 11.33,1.09 11.33,2.43 L11.33,12.14 C11.33,12.59 10.97,12.95 10.52,12.95 L0.81,12.95 C0.36,12.95 -1.77635684e-15,12.59 -1.77635684e-15,12.14 L-1.77635684e-15,2.43 C-1.77635684e-15,1.09 1.09,0 2.43,0 L2.43,0 Z M4.86,9.71 L6.48,9.71 C6.93,9.71 7.29,10.07 7.29,10.52 L7.29,12.14 C7.29,12.59 6.93,12.95 6.48,12.95 L4.86,12.95 C4.41,12.95 4.05,12.59 4.05,12.14 L4.05,10.52 C4.05,10.07 4.41,9.71 4.86,9.71 L4.86,9.71 Z M3.24,2.43 L4.05,2.43 M3.24,4.86 L4.05,4.86 M3.24,7.29 L4.05,7.29 M7.29,2.44 L8.1,2.44 M7.29,4.87 L8.1,4.87 M7.29,7.3 L8.1,7.3"
            id="uuid-62b2a2f1-51cb-4059-adfa-8fb9fa605bf5"
          ></path>
        </g>
        <g id="uuid-fd64d5b8-bd3b-4e4e-aadf-3033350575ca" stroke-width="1.46">
          <path
            d="M4.15,8.74 L5.15,8.74 C5.4239726,8.74 5.65,9.07 5.65,9.47 L5.65,10.93 C5.65,11.33 5.4239726,11.66 5.15,11.66 L4.15,11.66 C3.8760274,11.66 3.65,11.33 3.65,10.93 L3.65,9.47 C3.65,9.07 3.8760274,8.74 4.15,8.74 L4.15,8.74 Z M2.92,2.18 L3.65,2.18 M2.92,4.37 L3.65,4.37 M2.92,6.56 L3.65,6.56 M10.21,1.85235432 L10.21,1.78 C10.21,1.05077801 9.23,0 8.03,0 L2.19,0 C0.98,0 0,0.98 0,2.19 L0,10.94 C0,11.34 0.33,11.67 0.73,11.67 L6.087695,11.67"
            id="uuid-326fb590-b92a-49a4-a1a1-3a785732de38"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
