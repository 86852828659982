import { getLocalUser } from "@/services/auth";

export default function auth({ next, to }) {
  getLocalUser().then(user => {
    const isRoutePrivate = !to.matched.some(record => record.meta.public);

    const noAuth = !user || !user.isLoggedIn;

    if (isRoutePrivate && noAuth) {
      return next({ name: "login" });
    }

    return next();
  });
}
