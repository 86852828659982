const moment = require("moment");
const deliveryStatuses = require("../../constants/deliveryStatuses");
const agaProducts = require("../../constants/agaProducts");

/**
 * Remove delivered consommables (except AGA products) that are two years old.
 *
 * @param {Array} deliveries
 * @returns {Array} Deliveries
 */
const removeOldDeliveredConsommables = deliveries => {
  if (!deliveries || !Array.isArray(deliveries) || !deliveries.length) {
    return [];
  }
  const twoYearsAgo = moment().subtract(2, "years").startOf("day");
  return deliveries.filter(delivery => {
    if (!delivery.product) {
      return false;
    }
    const isConsommable = delivery.product.typeId !== "machine";
    const isAgaProduct = agaProducts.includes(delivery.productId);
    const isDelivered = delivery.statusId === deliveryStatuses.delivered;
    const isMoreThanTwoYearsOld = moment(delivery.deliveryDate).isBefore(twoYearsAgo);
    return isConsommable && !isAgaProduct && isDelivered && isMoreThanTwoYearsOld && !delivery.show ? false : true;
  });
};

module.exports = removeOldDeliveredConsommables;
