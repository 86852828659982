/**
 * Return formatted channels
 * @param {Array} interventions
 * @returns {Array}
 */

const formatChannels = channels => {
  if (!channels || !Array.isArray(channels) || !channels.length) return [];
  /* Sorts channels by label */
  const sortedChannels = channels.sort((current, next) => (current.label > next.label ? 1 : -1));
  /* Format channel and replace hex '&' code (&amp;) */
  return sortedChannels.map(item => ({ ...item, label: `${item.label.replace("&amp;", "&")} (${item._id})`, value: item._id }));
};

export default formatChannels;
