<template functional>
  <svg :width="props.width" :height="props.height" :class="`icon-${props.color}`" viewBox="0 0 24 24">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <path
        class="icon-stroked"
        d="M20,7 L20,11 M18,9 L22,9 M4,4 L12,4 C13.6568542,4 15,5.34314575 15,7 L15,19 C15,19.5522847 14.5522847,20 14,20 L10.0005861,20 L10.0005861,20 L8.06168575,20 L6.00470124,20 L2,20 C1.44771525,20 1,19.5522847 1,19 L1,7 C1,5.34314575 2.34314575,4 4,4 Z M7,16 L9,16 C9.55228475,16 10,16.4477153 10,17 L10,19 C10,19.5522847 9.55228475,20 9,20 L7,20 C6.44771525,20 6,19.5522847 6,19 L6,17 C6,16.4477153 6.44771525,16 7,16 Z M5,7 L6,7 M5,10 L6,10 M5,13 L6,13 M10,7 L11,7 M10,10 L11,10 M10,13 L11,13"
      ></path>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
