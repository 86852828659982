import keepMostRecentInterventions from "@/helpers/_functions/patient/keepMostRecentInterventions";

const interventionStatuses = require("@common/constants/interventionStatuses");

/**
 * Sort interventions by planification (past or planned).
 *
 * @param {Array} interventions
 * @returns {Object} { interventionsPast, interventionsPlanned }
 */

const sortInterventionsByPlanification = (interventions, user) => {
  const pastStatuses = [
    interventionStatuses.achieved,
    interventionStatuses.validated,
    interventionStatuses.unreachable,
    interventionStatuses.impossible
  ];

  if (!user?.isPrescripteur && !user?.isPatient) {
    pastStatuses.push(interventionStatuses.canceled);
  }

  const plannedStatuses = [
    interventionStatuses.planned,
    interventionStatuses.toBePrepared,
    interventionStatuses.toBeDelivered,
    interventionStatuses.confirmed,
    interventionStatuses.waiting
  ];
  const interventionsPast = keepMostRecentInterventions(interventions?.filter(intervention => pastStatuses.includes(intervention.statusId)));
  const interventionsPlanned = interventions?.filter(intervention => plannedStatuses.includes(intervention.statusId));
  return { interventionsPast, interventionsPlanned };
};

export default sortInterventionsByPlanification;
