import http from "@/services/http";

/**
 * @param {Object} params
 * @returns {Array<Object>} users
 */
export const searchUsers = params => http.get("/users/search", { params });

/**
 * @param {Object} params
 * @returns {Array<Object>} technicians
 */
export const searchIntervenants = params => http.get("/users/technicians/search", { params });

/**
 * @param {Object} params
 * @returns {Array<Object>} delegates
 */
export const searchDelegates = params => http.get("/users/delegates/search", { params });

/**
 * @param {Object} params
 * @returns {Array<Object>} delegates
 */
export const searchDelegatesToAssign = params => http.get("/users/delegates-to-assign/search", { params });

/**
 * @param {Array<Object>} params
 * @returns {Array<Object>} users
 */
export const fetchUsers = params => http.get("/users", { params });

/**
 * @param {String} idUser
 * @returns {Object} user
 */
export const fetchUser = (idUser, acceptEmptyResult = false) => {
  if (acceptEmptyResult) {
    return http.get(`/users/${idUser}`, { params: { acceptEmptyResult } });
  }

  return http.get(`/users/${idUser}`);
};

/**
 * @returns {Array<Object>} technicians
 */
export const fetchIntervenants = params => http.get("/users/technicians", { params });

export const archiveUser = data => http.put(`/users/archive`, data);

export const banUser = data => http.put(`/users/ban`, data);
