import getTreatmentsFromForfaits from "@common/helpers/forfaits/getTreatmentsFromForfaits";
import interventionTypes from "@common/constants/interventionTypes";
import moment from "moment";

const keepMostRecentInterventions = interventions => {
  if (!interventions?.length) {
    return;
  }

  const recentInterventions = interventions.filter(intervention => {
    const treatments = getTreatmentsFromForfaits(intervention.forfaits);
    const treatmentsAreOxy = treatments.some(treatment => treatment.typeId === "oxy");
    const isInstallation = intervention.typeId === interventionTypes.installation;
    const isDesappareillage = intervention.typeId === interventionTypes.recovery;
    const lastYearOxy = moment(intervention.plannedDateTimeStart).isBefore(moment().subtract(5, "years"));
    const lastYearOthers = moment(intervention.plannedDateTimeStart).isBefore(moment().subtract(3, "years"));

    return isInstallation || isDesappareillage || (treatmentsAreOxy && !lastYearOxy) || (!treatmentsAreOxy && !lastYearOthers);
  });

  return recentInterventions;
};

export default keepMostRecentInterventions;
