<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 16"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <line x1="4.974" x2="22.026" y1="2" y2="2" class="icon-stroked" />
    <line x1="4.974" x2="22.026" y1="8" y2="8" class="icon-stroked" />
    <line x1="4.974" x2="22.026" y1="14" y2="14" class="icon-stroked" />
    <text fill="#383838" font-family="Arial-BoldMT, Arial" font-size="3" font-weight="bold">
      <tspan x=".166" y="3">1</tspan>
    </text>
    <text fill="#383838" font-family="Arial-BoldMT, Arial" font-size="3" font-weight="bold">
      <tspan x=".166" y="9">2</tspan>
    </text>
    <text fill="#383838" font-family="Arial-BoldMT, Arial" font-size="3" font-weight="bold">
      <tspan x=".166" y="15">3</tspan>
    </text>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
