const locationTypeIds = {
  inPatient: "CP",
  poleVisit: "VP",
  prescriberVisit: "VM",
  inCommunity: "EC"
};

const locationTypes = [
  { _id: locationTypeIds.inCommunity, label: "En collectivité", supLabel: "Adresse collectivité" },
  { _id: locationTypeIds.inPatient, label: "Chez le patient", supLabel: "Adresse Patient" },
  { _id: locationTypeIds.prescriberVisit, label: "Visite médecin", supLabel: "Adresse médecin" },
  { _id: locationTypeIds.poleVisit, label: "Visite Pôle" }
];

const locationTypesMedpro = [
  { _id: locationTypeIds.inCommunity, label: "At the establishment", supLabel: "Establishment address" },
  { _id: locationTypeIds.poleVisit, label: "At the office" },
  { _id: locationTypeIds.inPatient, label: "At home", supLabel: "Patient address" },
  { _id: locationTypeIds.prescriberVisit, label: "At the physician's office", supLabel: "Physician address" }
];

const locationTypesDiabete = [
  { _id: locationTypeIds.inPatient, label: "Chez le patient", supLabel: "Adresse Patient" },
  { _id: locationTypeIds.prescriberVisit, label: "Visite médecin", supLabel: "Adresse médecin" },
  { _id: locationTypeIds.poleVisit, label: "Visite Pôle" }
];

module.exports = { locationTypeIds, locationTypes, locationTypesDiabete, locationTypesMedpro };
