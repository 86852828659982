import { logout } from "@/modules/common/_api";
import { removeAppUser } from "@/services/auth";
import { EventBus } from "@/services/event-bus";
import router from "@/router";

import * as api from "../_api";

const state = {
  loader: true,
  user: null,
  errorVerifyMessage: null,
  errorUpdateMessage: null,
  formErrorMessage: null
};

const getters = {
  formErrorMessage: state => state.formErrorMessage,
  errorVerifyMessage: state => state.errorVerifyMessage,
  errorUpdateMessage: state => state.errorUpdateMessage,
  loader: state => state.loader,
  user: state => state.user
};

const mutations = {
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_LOADER: (state, stateLoader) => {
    state.loader = stateLoader;
  },
  SET_STATE_MESSAGES: (state, { form, verify, update }) => {
    state.errorVerifyMessage = verify;
    state.errorUpdateMessage = update;
    state.formErrorMessage = form;
  }
};

const actions = {
  verifyUserToken(context, { type, token }) {
    api
      .verifyUserToken(type, token)
      .then(response => {
        context.commit("SET_USER", response.data.body);
        context.commit("SET_LOADER", false);
      })
      .catch(error => {
        if (error.status === 400) {
          router.push({ name: "login" });
          return;
        }

        context.commit("SET_STATE_MESSAGES", {
          form: null,
          verify: error.data.errorMessage,
          update: null
        });
      });
  },
  registerUserCredentials(context, credentials) {
    context.commit("SET_STATE_MESSAGES", {
      form: null,
      verify: null,
      update: null
    });
    return new Promise(resolve => {
      api
        .registerUserCredentials({ ...credentials, _id: context.state.user._id, entityId: context.state.user.entityId })
        .then(() => {
          context
            .dispatch("login/loginUser", credentials, { root: true })
            .then(() => {
              resolve();
            })
            .catch(err => {
              context.commit("SET_STATE_MESSAGES", {
                form: null,
                verify: null,
                update: err.data.errorMessage
              });
            });
        })
        .catch(error => {
          context.commit("SET_STATE_MESSAGES", {
            form: null,
            verify: null,
            update: error.data.errorMessage
          });
        });
    });
  },
  setStatusMessages(context, status) {
    context.commit("SET_STATE_MESSAGES", status);
  },
  disconnectConnectedUser(context) {
    logout()
      .then(() => {
        removeAppUser()
          .then(() => {
            EventBus.$emit("logout");
            context.dispatch("login/forceUpdate", null, { root: true });
          })
          .catch(errRemoveLocalUser => {
            console.error(errRemoveLocalUser);
          });
      })
      .catch(errLogout => {
        console.error(errLogout);
      });
  },
  checkUsernameAvailability(context, params) {
    return api.checkUsernameAvailability(params);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
