/**
 * This mapping prevents from using intervention's
 * status id directly for business logic
 */
module.exports = {
  planned: "planned",
  toBePrepared: "toBePrepared",
  toBeDelivered: "toBeDelivered",
  achieved: "achieved",
  impossible: "impossible",
  confirmed: "confirmed",
  validated: "validated",
  waiting: "waiting",
  unreachable: "unreachable",
  canceled: "canceled",
  closed: "closed"
};
