import http from "@/services/http";

/**
 *
 * @param {Object} params
 * * @param {String} params.productId
 * * @param {[String]} params.stockIds
 * * @param {Boolean} params.maintenanceInfos
 * @returns {Promise}
 */
const fetchInStockByCipWithMaintenanceInfos = params => http.get("/stocks/by-cip-with-maintenance-infos", { params });

export default fetchInStockByCipWithMaintenanceInfos;
