import i18n from "@/i18n";

const isNumber = require("lodash/isNumber");

/**
 * Helper that build the path to acces and return the traduction of i18l.
 *
 * @param {string} path     Path to the dictionary.
 *                          Exemple for patient/view/SegmentPatientInfo.fr.json => patient.view.segment-patient-info
 * @param {string} code     Code that represent the object to select in the dictionary file. Generaly formated with {HtmlBlockType}--{blockName}.
 *                          Exemple : p--firstname
 * @param {object} params   Params is an option if a value need to be insert in the traduction. The object's key need to correspond with the dictionary
 *
 * @param {int} counter     counter is used for plurality.
 *                          Exemple for "label--date" : "0 object | 1 object | 2 or more objects"
 *                          counter = 0 => first section
 *                          counter = 1 => second section
 *                          counter >= 2 => third section
 *
 * @returns
 */
const localize = (path, code, params = {}, counter) => {
  const fullPath = code ? `${path}.${code}` : `${path}`;

  if (isNumber(counter)) {
    return i18n.tc(fullPath, counter, params);
  }

  return i18n.t(fullPath, params);
};

export { localize };
