<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M2,4.5 L2,18.5 C2,19.0522847 2.44771525,19.5 3,19.5 L21,19.5 C21.5522847,19.5 22,19.0522847 22,18.5 L22,4.5 L22,4.5 M3.42857143,18.0628743 L9.14285714,12.3143713 M20.7142857,18.0628743 L15,12.3143713 M22,4.5 L12,13.3023952 L2,4.5 L22,4.5 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMailOutlined",
  mixins: [iconMixin]
};
</script>
