/**
 * Merge field mainLocation and secondaryLocations into one array with mainLocation at index 0
 *
 * @param {Object} patient
 * @returns {Array} patientLocations
 */

const mergePatientLocations = patient => {
  if (!patient) {
    return;
  }

  let patientLocations = [];

  if (patient.mainLocation && patient.secondaryLocations?.length) {
    patientLocations = [patient.mainLocation, ...patient.secondaryLocations];
  } else if (patient.mainLocation) {
    patientLocations = [patient.mainLocation];
  } else if (patient.secondaryLocations?.length) {
    patientLocations = [...patient.secondaryLocations];
  } else if (patient.locations) {
    patientLocations = [...patient.locations];
  }

  return patientLocations;
};

module.exports = mergePatientLocations;
