<template functional>
  <svg :width="props.width" :height="props.height" :class="`icon-${props.color}`" viewBox="0 0 24 24">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(5.125, 8.125)">
        <path
          d="M6.75,6.375 C6.75,6.58210678 6.58210678,6.75 6.375,6.75 L0.375,6.75 C0.167893219,6.75 0,6.58210678 0,6.375 C0,4.70618092 1.52134962,3.375 3.375,3.375 C5.22865038,3.375 6.75,4.70618092 6.75,6.375 Z M3.375,3 C2.54657288,3 1.875,2.32842712 1.875,1.5 C1.875,0.671572875 2.54657288,0 3.375,0 C4.20342712,0 4.875,0.671572875 4.875,1.5 C4.875,2.32842712 4.20342712,3 3.375,3 Z"
          class="icon-filled"
        ></path>
      </g>
      <path
        d="M1,8.5 L3,8.5 M1,12 L3,12 M1,15.5 L3,15.5 M2,5.77777778 L2,4.88888889 C2,4.39796911 2.38801988,4 2.86666667,4 L14.1333333,4 C14.6119801,4 15,4.39796911 15,4.88888889 L15,19.1111111 C15,19.6020309 14.6119801,20 14.1333333,20 L2.86666667,20 C2.38801988,20 2,19.6020309 2,19.1111111 L2,18.2222222"
        class="icon-stroked"
      ></path>
      <line class="icon-stroked" x1="20" y1="7" x2="20" y2="11"></line>
      <line class="icon-stroked" x1="18" y1="9" x2="22" y2="9"></line>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMailOutlined",
  mixins: [iconMixin]
};
</script>
