<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M4,15 L11.2928932,7.70710678 C11.6834175,7.31658249 12.3165825,7.31658249 12.7071068,7.70710678 L20,15 L20,15"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
