<template functional>
  <svg
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      class="icon-stroked"
      d="M19.1949107,2.59580719 C17.7125074,1.87853431 13.8705429,1.8522749 13.0479165,6.82200496 C12.7392995,8.92525908 11.9956165,9.44079824 11.6739289,9.74894428 C11.3522413,10.0570903 4.08510564,17.3028974 3.25804725,18.1028046 C2.43098886,18.9027118 1.40574984,20.4474846 2.42755244,21.4879368 C3.44935505,22.5283889 5.01641443,21.5177656 5.51310125,21.0244541 C6.00978808,20.5311427 14.1383166,12.3655446 14.3522413,12.1827723 C14.566166,12 15.0899267,11.3207256 16.3884608,11.135551 C17.6869949,10.9503765 22.7740712,10.6849703 21.7880671,5.65504991 C20.8228604,6.65684657 19.3887059,7.7547895 18.5241639,7.71787082 C18.0618099,7.09475044 17.165655,6.16638078 16.702517,5.4218234 C16.4716026,4.90360278 17.6670839,3.16251014 19.1949107,2.59580719 Z"
      id="Path-10"
      stroke-width="2"
    ></path>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMaintenance",
  mixins: [iconMixin]
};
</script>
