<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M9,3 C10.0100002,3 10.8451398,3.74866702 10.9807397,4.72132186 C13.6707383,5.40337822 14.7661724,7.66661404 15.2318891,11.5538846 C15.2676123,11.8520603 15.2825197,11.9876206 15.3535442,12.6472828 C15.6363706,15.2741228 15.9125402,16.2589244 16.5376777,16.6557087 C17.3828634,17.1921609 17.0028525,18.5 16.0017932,18.5 L11.9584039,18.5006962 C11.7200781,19.9192103 10.486255,21 9,21 C7.51374499,21 6.27992193,19.9192103 6.0415961,18.5006962 L2.00179317,18.5 C1.00075024,18.5 0.620727129,17.1921956 1.46588206,16.6557256 C2.09094575,16.2589605 2.36710311,15.2741807 2.64992771,12.6474534 C2.72096757,11.9876722 2.73587675,11.8521016 2.77160786,11.5538774 C3.23711731,7.66857414 4.33173817,5.40564073 7.0183111,4.72221545 C7.15372284,3.749687 7.98931203,3 9,3 Z M20,7 C20.5128358,7 20.9355072,7.38604019 20.9932723,7.88337887 L21,8 L21,9 L22,9 C22.5522847,9 23,9.44771525 23,10 C23,10.5128358 22.6139598,10.9355072 22.1166211,10.9932723 L22,11 L21,11 L21,12 C21,12.5522847 20.5522847,13 20,13 C19.4871642,13 19.0644928,12.6139598 19.0067277,12.1166211 L19,12 L19,11 L18,11 C17.4477153,11 17,10.5522847 17,10 C17,9.48716416 17.3860402,9.06449284 17.8833789,9.00672773 L18,9 L19,9 L19,8 C19,7.44771525 19.4477153,7 20,7 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
