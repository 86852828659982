import extractInterventionLocations from "@common/helpers/interventions/extractInterventionLocations";

const bindNewInterventionData = data => {
  if (!data || typeof data !== "object" || !Object.keys(data).length) {
    return {};
  }

  const { patient, technician, plannedDate, locationIndex, ...intervention } = data;
  const contract = patient && patient.contracts.find(item => item.id === intervention.contractId);

  intervention.channelId = contract?.channelId || null;
  intervention.forfaitIds = contract?.forfaitIds || null;
  intervention.intervenantExternalId = technician.externalId;
  intervention.intervenantId = technician._id;
  intervention.location = intervention?.location || extractInterventionLocations(patient, contract.id, __PLATFORM__)[locationIndex];
  intervention.patientId = patient._id;
  intervention.plannedDateTimeStart = plannedDate.start || null;
  intervention.plannedDateTimeEnd = plannedDate.end || null;
  intervention.prescriptionAddressId = contract?.mainPrescriptionAddressId || null;
  intervention.installDate = contract?.installDate || null;

  if (contract.divisionId === "D") {
    intervention.reimbursementModeId = "TP";
  } else {
    intervention.reimbursementModeId = contract ? "TP" : "HP";
  }

  if (contract.pathologyIds && contract.pathologyIds.length) {
    intervention.pathologyIds = contract.pathologyIds;
  }

  if (contract.deliveryPreference) {
    intervention.deliveryPreference = contract.deliveryPreference;
  }

  if (contract.pumpId) {
    intervention.pumpId = contract.pumpId;
  }

  return intervention;
};

export default bindNewInterventionData;
