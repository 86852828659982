<template functional>
  <span class="icon-copy-container">
    <svg
      :class="`icon-${props.color}`"
      :width="props.width"
      :height="props.height"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="none"
        class="icon-stroked"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M8,3 L20,3 C20.5522847,3 21,3.44771525 21,4 L21,16 C21,16.5522847 20.5522847,17 20,17 L8,17 C7.44771525,17 7,16.5522847 7,16 L7,4 C7,3.44771525 7.44771525,3 8,3 Z M17,18 L17,20 C17,20.5522847 16.5522847,21 16,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,8 C3,7.44771525 3.44771525,7 4,7 L6,7"
      />
    </svg>
    <svg
      :class="`icon-${props.color}`"
      :width="props.width"
      :height="props.height"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        class="icon-filled"
        fill="none"
        d="M1671 566q0 40-28 68l-724 724-136 136q-28 28-68 28t-68-28l-136-136-362-362q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 295 656-657q28-28 68-28t68 28l136 136q28 28 28 68z"
      />
    </svg>
  </span>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
