import eachSeries from "async/eachSeries";
import uniqBy from "lodash/uniqBy";
import bindQueryToFetch from "@/mixins/list/_functions/bindQueryToFetch";
import {
  fetchUsers,
  fetchPatients,
  fetchPrescribers,
  fetchZones,
  fetchLocations,
  fetchCommunities,
  fetchPrescribersGroups,
  fetchProducts,
  fetchProviders,
  fetchStocks,
  fetchGasOxygenLifecycles,
  fetchCities
} from "@/modules/common/_api";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  bindQueryToUrl(context, urlQuery) {
    return new Promise((resolve, reject) => {
      if (!urlQuery || !Object.entries(urlQuery).length) return resolve({});

      const formattedQuery = {};

      return eachSeries(
        Object.entries(urlQuery),
        ([key, value], nextQuery) => {
          if (!bindQueryToFetch[key]) {
            formattedQuery[key] = value;
            return nextQuery();
          }
          return context
            .dispatch(bindQueryToFetch[key], { key, value })
            .then(result => {
              Object.assign(formattedQuery, result);
            })
            .catch(err => {
              console.error(`Error while fetching ${key} - ${err}`);
            })
            .finally(() => nextQuery());
        },
        errLoopKeys => {
          if (errLoopKeys) return reject(new Error(errLoopKeys));
          return resolve(formattedQuery);
        }
      );
    });
  },
  fetchUsers(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ users: [] });
        return;
      }

      const ids = typeof value === "string" ? [value] : value;

      fetchUsers({ query: { ids } })
        .then(res => {
          resolve({ users: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchDelegates(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ users: [] });
        return;
      }

      const delegateIds = typeof value === "string" ? [value] : value;

      fetchUsers({ query: { ids: delegateIds } })
        .then(res => {
          resolve({ delegates: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPrescribers(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ prescribers: [] });
        return;
      }

      const ids = typeof value === "string" ? [value] : value;

      fetchPrescribers({ query: { ids } })
        .then(res => {
          resolve({ prescribers: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPrescribersFromPrescriptionAddresses(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ prescribers: [] });
        return;
      }

      const prescriptionAddresses = typeof value === "string" ? [value] : value;

      fetchPrescribers({ query: { prescriptionAddresses } })
        .then(res => {
          resolve({ prescribers: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPatientsFromContracts(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ patients: [] });
        return;
      }

      const contractIds = typeof value === "string" ? [value] : value;

      fetchPatients({ query: { contractIds } })
        .then(res => {
          resolve({ patients: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPatients(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ patients: [] });
        return;
      }

      const patientIds = typeof value === "string" ? [value] : value;

      fetchPatients({ query: { patientIds } })
        .then(res => {
          resolve({ patients: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchZones(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ zones: [] });
        return;
      }

      const zoneIds = typeof value === "string" ? [value] : value;

      fetchZones({ query: { zoneIds } })
        .then(res => {
          resolve({ zones: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchTechniciansFromZones(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ technicians: [] });
        return;
      }

      const zoneIds = typeof value === "string" ? [value] : value;

      fetchZones({ query: { zoneIds } })
        .then(res => {
          resolve({ technicians: res.data.body.results.map(item => ({ ...item.mainTech, zoneId: item._id })) });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchLocations(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ locationCodes: [] });
        return;
      }

      const locationCodes = typeof value === "string" ? [value] : value;

      fetchLocations({ query: { locationCodes } })
        .then(res => {
          resolve({ locationCodes: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCities(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ cities: [] });
        return;
      }

      const cities = typeof value === "string" ? [value] : value;

      fetchCities({ query: { cities } })
        .then(res => {
          resolve({ cities: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchCommunities(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ communities: [] });
        return;
      }

      const communityIds = typeof value === "string" ? [value] : value;

      fetchCommunities({ query: { communityIds } })
        .then(res => {
          resolve({ communities: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchPrescribersGroups(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ prescribersGroups: [] });
        return;
      }

      const prescribersGroupIds = typeof value === "string" ? [value] : value;

      fetchPrescribersGroups({ query: { prescribersGroupIds } })
        .then(res => {
          resolve({ prescribersGroups: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchProducts(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ products: [] });
        return;
      }

      const productIds = typeof value === "string" ? [value] : value;

      fetchProducts({ query: { productIds } })
        .then(res => {
          resolve({ products: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchProviders(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ prescribersGroups: [] });
        return;
      }

      const providerIds = typeof value === "string" ? [value] : value;

      fetchProviders({ query: { providerIds } })
        .then(res => {
          resolve({ providers: res.data.body.results });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchStocks(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ stocks: [] });
        return;
      }

      const stockIds = typeof value === "string" ? [value] : value;

      fetchStocks({ query: { stockIds } })
        .then(res => {
          resolve({ stocks: res.data.body });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchGasOxygenLifecyclesByAgaNumbers(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ agaNumbers: [] });
        return;
      }

      const agaNumbersToQuery = typeof value === "string" ? [value] : value;

      fetchGasOxygenLifecycles({ query: { gasOxygenLifecycleAgaNumbers: agaNumbersToQuery } })
        .then(res => {
          resolve({ agaNumbers: uniqBy(res.data.body?.results, "agaNumber") });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchGasOxygenLifecyclesByBatchNumbers(context, { value }) {
    return new Promise((resolve, reject) => {
      if (!value) {
        resolve({ batchNumbers: [] });
        return;
      }

      const batchNumbersToQuery = typeof value === "string" ? [value] : value;

      fetchGasOxygenLifecycles({ query: { gasOxygenLifecycleBatchNumbers: batchNumbersToQuery } })
        .then(res => {
          resolve({ batchNumbers: uniqBy(res.data.body?.results, "batchNumber") });
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  mapArray(context, { key, value }) {
    return typeof value === "string" ? { [key]: [value] } : { [key]: value };
  },
  bindBoolean(context, { key, value }) {
    return value === "all" ? { [key]: value } : { [key]: value === "true" || value === true };
  },
  mapBooleanArray(context, { key, value }) {
    return typeof value === "string"
      ? { [key]: [value === "true" || value === true] }
      : {
          [key]: value.map(bool => {
            return bool === "true" || bool === true;
          })
        };
  }
};

export default { state, getters, mutations, actions, namespaced: true };
