/* eslint-disable no-shadow */
import storage from "@common/services/storage";

export const state = {
  localBuildVersion: null
};

export const getters = {
  localBuildVersion: state => state.localBuildVersion
};

export const mutations = {
  SET_LOCAL_BUILD_VERSION: (state, localBuildVersion) => {
    state.localBuildVersion = localBuildVersion;
  }
};

export const actions = {
  initLocalBuildVersion(context) {
    return new Promise(resolve => {
      context
        .dispatch("getLocalBuildVersion")
        .then(hash => {
          context.commit("SET_LOCAL_BUILD_VERSION", hash);
        })
        .finally(() => resolve());
    });
  },
  setLocalBuildVersion(context, hash) {
    context.commit("SET_LOCAL_BUILD_VERSION", hash);
    return storage.setItem("buildVersion", hash);
  },
  getLocalBuildVersion() {
    return storage.getItem("buildVersion");
  }
};

export default { state, getters, mutations, actions, namespaced: true };
