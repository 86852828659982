<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <title v-if="props.tooltipText">{{ props.tooltipText }}</title>
    <path
      class="icon-filled"
      fill-rule="evenodd"
      d="M13.3137085,2 L21.7989899,10.4852814 C22.5800385,11.26633 22.5800385,12.5326599 21.7989899,13.3137085 L13.3137085,21.7989899 C12.5326599,22.5800385 11.26633,22.5800385 10.4852814,21.7989899 L2,13.3137085 C1.21895142,12.5326599 1.21895142,11.26633 2,10.4852814 L10.4852814,2 C11.26633,1.21895142 12.5326599,1.21895142 13.3137085,2 Z M11.8994949,14.8994949 C11.3472102,14.8994949 10.8994949,15.3472102 10.8994949,15.8994949 L10.8994949,15.8994949 L10.8994949,16.8994949 C10.8994949,17.4517797 11.3472102,17.8994949 11.8994949,17.8994949 C12.4517797,17.8994949 12.8994949,17.4517797 12.8994949,16.8994949 L12.8994949,16.8994949 L12.8994949,15.8994949 C12.8994949,15.3472102 12.4517797,14.8994949 11.8994949,14.8994949 Z M11.8994949,5.89949494 C11.3472102,5.89949494 10.8994949,6.34721019 10.8994949,6.89949494 L10.8994949,6.89949494 L10.8994949,12.8994949 C10.8994949,13.4517797 11.3472102,13.8994949 11.8994949,13.8994949 C12.4517797,13.8994949 12.8994949,13.4517797 12.8994949,12.8994949 L12.8994949,12.8994949 L12.8994949,6.89949494 C12.8994949,6.34721019 12.4517797,5.89949494 11.8994949,5.89949494 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
