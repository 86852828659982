<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M15,3 L15,6 C15,6.55228475 14.5522847,7 14,7 L12,7 L12,7 L12,17 L14,17 C14.5522847,17 15,17.4477153 15,18 L15,21 C15,21.5522847 14.5522847,22 14,22 L12,22 C10.3431458,22 9,20.6568542 9,19 L9,5 C9,3.34314575 10.3431458,2 12,2 L14,2 C14.5522847,2 15,2.44771525 15,3 Z"
      transform="rotate(-30 12 12)"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMailOutlined",
  mixins: [iconMixin]
};
</script>
