import * as api from "../_api";

const state = {
  ordoclic: {
    active: true,
    updateDate: null
  },
  ordoclicErrorMessage:
    "Le service de signature électronique subit actuellement des perturbations.</br>La signature de renouvellements ainsi que le téléchargement de documents signés peuvent être impactés."
};

const getters = {
  ordoclicInfos: state => {
    return {
      isAvailable: state.ordoclic.active,
      updateDate: state.ordoclic.updateDate,
      errorMessage: state.ordoclicErrorMessage
    };
  }
};

const mutations = {
  SET_ORDOCLIC_STATUS: (state, data) => {
    state.ordoclic.active = data.active;
    state.ordoclic.updateDate = data.updateDate;
  }
};

const actions = {
  getOrdoclicStatus(context) {
    api.getOrdoclicStatus().then(response => {
      if (!response.data) return;
      context.commit("SET_ORDOCLIC_STATUS", response.data);
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
