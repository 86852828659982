import * as api from "../_api";

const state = {
  loader: true,
  patientId: null,
  contractId: null,
  errorVerifyMessage: null,
  formErrorMessage: null
};

const getters = {
  formErrorMessage: state => state.formErrorMessage,
  errorVerifyMessage: state => state.errorVerifyMessage,
  loader: state => state.loader,
  patientId: state => state.patientId,
  contractId: state => state.contractId
};

const mutations = {
  SET_LOADER: (state, stateLoader) => {
    state.loader = stateLoader;
  },
  SET_PATIENT_ID: (state, patientId) => {
    state.patientId = patientId;
  },
  SET_CONTRACT_ID: (state, contractId) => {
    state.contractId = contractId;
  },
  SET_STATE_MESSAGES: (state, { form, verify }) => {
    state.errorVerifyMessage = verify;
    state.formErrorMessage = form;
  }
};

const actions = {
  verifyTemporaryLink(context, { token }) {
    api
      .verifyTemporaryLink(token)
      .then(response => {
        context.commit("SET_PATIENT_ID", response.data.bodypatientId);
        context.commit("SET_CONTRACT_ID", response.data.body.contractId);
        context.commit("SET_LOADER", false);
      })
      .catch(error => {
        context.commit("SET_STATE_MESSAGES", {
          form: null,
          verify: error.data.errorMessage
        });
      });
  },
  sendDataPaymentForm(context, { data }) {
    return new Promise((resolve, reject) => {
      context.commit("SET_LOADER", true);
      api
        .sendDataPaymentForm(data)
        .then(response => {
          context.commit("SET_LOADER", false);
          resolve(response.data.body);
        })
        .catch(err => {
          context.commit("SET_LOADER", false);
          context.commit("SET_STATE_MESSAGES", {
            form: err.data.errorMessage,
            verify: null
          });
          reject(err);
        });
    });
  },
  sendLinkForDataPaymentForm(context, { patientId, contractId }) {
    return new Promise((resolve, reject) => {
      api
        .sendLinkForDataPaymentForm({ patientId, contractId })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => reject(err));
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
