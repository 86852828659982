import i18n from "@/i18n";

const i18nKey = "router.extranet.payment";

// eslint-disable-next-line import/extensions
const payment = () => import(/* webpackChunkName: "public" */ "@/modules/payment/index.paymentForm");

export default [
  {
    path: "payment-data/:ddr",
    name: "paymentForm",
    component: payment,
    meta: {
      title: i18n.t(`${i18nKey}.title--paymentForm`),
      public: true
    }
  }
];
