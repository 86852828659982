<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M8,4 L16,4 C17.6568542,4 19,5.34314575 19,7 L19,19 C19,19.5522847 18.5522847,20 18,20 L14.0005861,20 L14.0005861,20 L12.0616857,20 L10.0047012,20 L6,20 C5.44771525,20 5,19.5522847 5,19 L5,7 C5,5.34314575 6.34314575,4 8,4 Z M11,16 L13,16 C13.5522847,16 14,16.4477153 14,17 L14,19 C14,19.5522847 13.5522847,20 13,20 L11,20 C10.4477153,20 10,19.5522847 10,19 L10,17 C10,16.4477153 10.4477153,16 11,16 Z M9,7 L10,7 M9,10 L10,10 M9,13 L10,13 M14,7 L15,7 M14,10 L15,10 M14,13 L15,13"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
