import { componentNameResolver } from "@common/helpers/settings/overrideResolver";

import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.extranet.patients";
const settings = require(`@common/settings/${__PLATFORM__}`);

const PatientViewMonitoring = () => import(/* webpackChunkName: "patient-view" */ "@/modules/patient/view/_components/PatientViewMonitoring");

export default [
  {
    path: "patient",
    component: () => {
      const component = componentNameResolver("PatientView", settings.overridedModules);
      return import(`@/modules/patient/view/${component}`);
    },
    children: [
      {
        path: "",
        name: "patientView",
        meta: {
          title: localize(localPath, "title--patientView")
        },
        redirect: to => {
          return { name: "patientViewRespiratoire", params: to.params };
        }
      },
      {
        path: "info",
        alias: "informations",
        name: "patientViewInformations",
        meta: {
          title: localize(localPath, "title--patientView")
        },
        component: () => {
          const component = componentNameResolver("PatientViewInformations", settings.overridedModules);
          return import(`@/modules/patient/view/_components/informations/${component}`);
        }
      },
      {
        path: "respiratory",
        alias: "respiratoire",
        name: "patientViewRespiratoire",
        props: true,
        meta: {
          title: localize(localPath, "title--patientView")
        },
        component: () => {
          const component = componentNameResolver("PatientViewRespiratory", settings.overridedModules);
          return import(`@/modules/patient/view/_components/${component}`);
        }
      },
      {
        path: "monitoring",
        name: "patientViewMonitoring",
        meta: {
          title: localize(localPath, "title--patientView")
        },
        component: PatientViewMonitoring
      },

      {
        path: "questionnaires",
        alias: "questionnaires",
        name: "patientViewQuestionnaires",
        meta: {
          title: localize(localPath, `title--patientView`)
        },
        component: () => {
          const component = componentNameResolver("TabsPatientViewQuestionnaires", settings.overridedModules);
          return import(`@/modules/patient/view/_components/questionnaires/${component}`);
        }
      }
    ]
  }
];
