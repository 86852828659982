<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M6,8 L18,8 C18.5522847,8 19,8.44771525 19,9 L19,18 C19,18.5522847 18.5522847,19 18,19 L6,19 C5.44771525,19 5,18.5522847 5,18 L5,9 C5,8.44771525 5.44771525,8 6,8 Z M4,5 L20,5 C20.5522847,5 21,5.44771525 21,6 L21,7 C21,7.55228475 20.5522847,8 20,8 L4,8 C3.44771525,8 3,7.55228475 3,7 L3,6 C3,5.44771525 3.44771525,5 4,5 Z M14.5,13 L12.7888266,15.1962 C12.4493837,15.631857 11.8210412,15.7098538 11.3853842,15.3704109 C11.3203552,15.3197434 11.2618409,15.2612291 11.2111734,15.1962 L9.5,13 L9.5,13 M12,14 L12,11"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
