<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M9,17 C9.55228475,17 10,17.4477153 10,18 L10,20 C10,20.5522847 9.55228475,21 9,21 L4,21 C3.44771525,21 3,20.5522847 3,20 L3,18 C3,17.4477153 3.44771525,17 4,17 L9,17 Z M20,11 C20.5522847,11 21,11.4477153 21,12 L21,20 C21,20.5522847 20.5522847,21 20,21 L15,21 C14.4477153,21 14,20.5522847 14,20 L14,12 C14,11.4477153 14.4477153,11 15,11 L20,11 Z M9,3 C9.55228475,3 10,3.44771525 10,4 L10,12 C10,12.5522847 9.55228475,13 9,13 L4,13 C3.44771525,13 3,12.5522847 3,12 L3,4 C3,3.44771525 3.44771525,3 4,3 L9,3 Z M20,3 C20.5522847,3 21,3.44771525 21,4 L21,6 C21,6.55228475 20.5522847,7 20,7 L15,7 C14.4477153,7 14,6.55228475 14,6 L14,4 C14,3.44771525 14.4477153,3 15,3 L20,3 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
