<template functional>
  <svg
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
    viewBox="0 0 24 21"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="EXTRANET-|-Patient--fiche"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g
        id="fiche-patient__extranet_accueil-copy"
        transform="translate(-62.000000, -423.000000)"
        stroke="#219B95"
        stroke-width="2"
      >
        <g id="actu-tech-copy-3" transform="translate(40.000000, 404.000000)">
          <g id="ic" transform="translate(23.500000, 20.666667)">
            <path
              d="M5.83333333,11.2592172 L1,11.2592172 C0.44771525,11.2592172 -6.76353751e-17,11.7069324 0,12.2592172 L0,17.2592172 C6.76353751e-17,17.8115019 0.44771525,18.2592172 1,18.2592172 L20,18.2592172 C20.5522847,18.2592172 21,17.8115019 21,17.2592172 L21,12.2592172 C21,11.7069324 20.5522847,11.2592172 20,11.2592172 L15.1666667,11.2592172 L15.1666667,11.2592172 M10.5,12.1499158 C11.1443322,12.1499158 11.6666667,11.6275814 11.6666667,10.9832492 C11.6666667,10.3389169 11.1443322,9.81658249 10.5,9.81658249 C9.85566779,9.81658249 9.33333333,10.3389169 9.33333333,10.9832492 C9.33333333,11.6275814 9.85566779,12.1499158 10.5,12.1499158 Z M13.7998316,6.03350169 C11.977385,4.21105499 9.02261505,4.21105499 7.20016835,6.03350169 M17.0996633,2.73367004 C13.4547699,-0.911223347 7.5452301,-0.911223347 3.90033671,2.73367004"
              id="ic--color--outlined"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
