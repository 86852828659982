/* eslint-disable no-shadow */
import getUserRolesLabel from "@common/helpers/roles/getUserRolesLabel";
import { login, sendResetPasswordEmail, setResourceLoginEmail, removeResourceSession, deleteResourceCookie } from "@/modules/common/_api";
import { setAppUser } from "@/services/auth";
import { EventBus } from "@/services/event-bus";

export const loginState = {
  errorMessage: null,
  resetErrorMessage: null,
  resetSuccessMessage: null,
  user: null,
  updateKey: 0
};

export const loginGetters = {
  errorMessage: state => state.errorMessage,
  resetErrorMessage: state => state.resetErrorMessage,
  resetSuccessMessage: state => state.resetSuccessMessage,
  updateKey: state => state.updateKey,
  $user: state => state.user
};

export const loginMutations = {
  FORCE_UPDATE: state => {
    state.updateKey += 1;
  },
  SET_USER: (state, user) => {
    state.user = user;
  },
  SET_ERROR_MESSAGE: (state, errorMessage) => {
    state.errorMessage = errorMessage;
  },
  SET_RESET_PASSWORD_ERROR: (state, errorMessage) => {
    state.resetErrorMessage = errorMessage;
  },
  SET_RESET_PASSWORD_SUCCESS: (state, successMessage) => {
    state.resetSuccessMessage = successMessage;
  }
};

export const loginActions = {
  loginUser(context, credentials) {
    return new Promise((resolve, reject) => {
      login(credentials)
        .then(response => {
          const user = response?.data?.body || {};
          context.dispatch("common/fetchRoles", {}, { root: true }).finally(() => {
            const { roles } = context.rootState.common.taxonomies || {};
            user.roles = getUserRolesLabel(user.roleIds, roles);
            setAppUser(user)
              .then(() => {
                EventBus.$emit("login");
                context.dispatch("setUser", user);
                context.dispatch("forceUpdate");
                resolve(user);
              })
              .catch(errLocalUser => reject(errLocalUser));
          });
        })
        .catch(errLogin => {
          context.commit("SET_ERROR_MESSAGE", errLogin.data.errorMessage);
          reject(errLogin);
        });
    });
  },
  setUser(context, user) {
    context.commit("SET_USER", user);
  },
  unsetUser(context) {
    context.commit("SET_USER", { isLoggedIn: false });
  },
  forceUpdate(context) {
    setTimeout(() => {
      context.commit("FORCE_UPDATE");
    }, 100);
  },
  clearErrorMessage(context) {
    context.commit("SET_ERROR_MESSAGE", null);
  },
  sendResetPasswordEmail(context, { email, entityTypes }) {
    return new Promise((resolve, reject) => {
      context.dispatch("clearResetErrorMessage");
      sendResetPasswordEmail({ email, entityTypes })
        .then(response => {
          context.commit("SET_RESET_PASSWORD_SUCCESS", {
            successMessage: response.data.body.successMessage,
            email
          });
          resolve();
        })
        .catch(err => {
          if (err.status === 418) {
            context.commit("SET_RESET_PASSWORD_ERROR", {
              type: "caution",
              message: err.data.errorMessage
            });
            return reject(err);
          }
          context.commit("SET_RESET_PASSWORD_ERROR", {
            type: "warning",
            message: err.data.errorMessage
          });
          reject(err);
        });
    });
  },
  clearResetErrorMessage(context) {
    context.commit("SET_RESET_PASSWORD_ERROR", null);
  },
  closeSuccessModal(context) {
    context.commit("SET_RESET_PASSWORD_SUCCESS", null);
  },
  setResourceEmail(context, data) {
    return setResourceLoginEmail(data);
  },
  removeResourceSession(context, data) {
    return removeResourceSession(data);
  },
  deleteResourceCookie() {
    return deleteResourceCookie();
  }
};
