<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M4 1.91667V22.0833C4 22.5896 4.40774 23 4.91071 23H20.0893C20.5923 23 21 22.5896 21 22.0833V5.88889L16.1429 1H4.91071C4.40774 1 4 1.41041 4 1.91667ZM21 5.88889L16.1429 1V5.88889H21Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
