<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
    viewBox="0 0 24 24"
    version="1.1"
  >
    <g
      class="icon-stroked"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path
        d="M19.1949107,2.59580719 C17.7125074,1.87853431 13.8705429,1.8522749 13.0479165,6.82200496 C12.7392995,8.92525908 11.9956165,9.44079824 11.6739289,9.74894428 C11.3522413,10.0570903 4.08510564,17.3028974 3.25804725,18.1028046 C2.43098886,18.9027118 1.40574984,20.4474846 2.42755244,21.4879368 C3.44935505,22.5283889 5.01641443,21.5177656 5.51310125,21.0244541 C6.00978808,20.5311427 14.1383166,12.3655446 14.3522413,12.1827723 C14.566166,12 15.0899267,11.3207256 16.3884608,11.135551 C17.6869949,10.9503765 22.7740712,10.6849703 21.7880671,5.65504991 C20.8228604,6.65684657 19.3887059,7.7547895 18.5241639,7.71787082 C18.0618099,7.09475044 17.165655,6.16638078 16.702517,5.4218234 C16.4716026,4.90360278 17.6670839,3.16251014 19.1949107,2.59580719 Z"
        id="Path-10"
        stroke-width="2"
      />
      <path
        d="M19.7124706,12.0547932 L18.0703851,13.7747309 L12.3427617,19.5023544 C11.7826605,20.269001 11.8507175,21.0123842 12.5469327,21.732504 C13.5912555,22.8126836 14.5990922,22.3814354 15.2963084,21.6196298 C15.9935245,20.8578855 22.2132272,14.5981517 22.2132272,14.5981517 C22.2132272,14.5981517 21.3796417,13.7503655 19.7124706,12.0547932 Z"
        id="Path-59"
        stroke-width="2"
        transform="translate(17.0907, 17.2181) rotate(-90) translate(-17.0907, -17.2181)"
      />
      <path
        d="M1.75264342,10.7670595 L4.04688522,8.35132266 C4.30527905,5.72769296 4.47434541,4.38401775 4.5540843,4.32029703 C4.67369263,4.22471595 8.72129839,2.01722938 9.04934944,2.01722938 C9.37740048,2.01722938 10.0745646,2.93990944 10.2545218,3.13265035 C10.4344789,3.32539127 8.30018422,7.29733026 8.15979177,7.48424844 C8.06619681,7.60886057 6.76183815,7.89788531 4.24671579,8.35132266"
        id="Path-40"
        stroke-width="2"
        transform="translate(6.009, 6.3921) rotate(-90) translate(-6.009, -6.3921)"
      />
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMaintenanceOnGoing",
  mixins: [iconMixin]
};
</script>
