import moment from "moment";

/**
 * fixNumber applies a toFixed with a chosen range of decimals. It also takes care of parseFloat and NaN checks,
 * so a String can safely be passed. If NaN, returns the argument itself
 * @param {Float | String} num
 * @param {Int} decimals
 * @returns {Float | Any}
 */
export default (dateString, format = "DD/MM/YYYY") => {
  const date = moment(dateString);
  if (date.isValid()) {
    return date.format(format);
  }
  return "";
};
