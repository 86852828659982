<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M12,10.5 C13.2426407,10.5 14.25,9.49264069 14.25,8.25 C14.25,7.00735931 13.2426407,6 12,6 C10.7573593,6 9.75,7.00735931 9.75,8.25 C9.75,9.49264069 10.7573593,10.5 12,10.5 Z M18,18 C18,15.1005051 15.3137085,12.75 12,12.75 C8.6862915,12.75 6,15.1005051 6,18 M22,16 C22,14.0670034 20.209139,12.5 18,12.5 C16.8926875,12.5 15.8904645,12.893699 15.1661852,13.5298494 M18,11 C18.8284271,11 19.5,10.3284271 19.5,9.5 C19.5,8.67157288 18.8284271,8 18,8 C17.1715729,8 16.5,8.67157288 16.5,9.5 C16.5,10.3284271 17.1715729,11 18,11 Z M2,16 C2,14.0670034 3.790861,12.5 6,12.5 C7.10731253,12.5 8.10953555,12.893699 8.83381478,13.5298494 M6,11 C5.17157288,11 4.5,10.3284271 4.5,9.5 C4.5,8.67157288 5.17157288,8 6,8 C6.82842712,8 7.5,8.67157288 7.5,9.5 C7.5,10.3284271 6.82842712,11 6,11 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
