import http from "@/services/http";

/**
 * @param {Object} params
 * @returns {Array<Object>} alerts
 */
export const fetchAlerts = params => http.get(`/alerts`, { params });

/**
 * @param {Object} params
 * @returns {Array<Object>} alerts
 */
export const incrementNoticeViewCount = noticeId => http.put("/noticesViewCount", noticeId);

/**
 * @param {String} patientId
 * @returns {Array<Object>} interventions
 */
export const fetchInterventions = patientId => http.get(`/patients/${patientId}/interventions`);

/**
 * @param {Object} params
 * @returns {Array<Object>} ordonnances
 */
export const fetchOrdonnances = params => http.get("/ordonnances", { params });

/**
 * @param {String} patientId
 * @returns {Object} insured
 */
export const fetchInsured = patientId => http.get(`/patients/${patientId}/insured`);

/**
 * @param {Object} data
 * @returns {Object} update response
 */
export const editAlerts = data => http.put(`/alerts`, data);

/**
 * @param {Object} data
 * @param {String} id
 * @returns {<Object>} update response
 */
export const editPatientContract = (id, data) => http.put(`/contracts/${id}`, data);

/**
 *
 * @param {Object} patient
 * @returns {Promise}
 */
export const createPatientOrdoclicAccount = (patientId, patient, rpps) =>
  http.post(`patients/${patientId}/third-party/create-patient`, { patient, rpps });

/**
 * @param {String} contractId
 * @returns {<Object>} delete renewal
 */
export const deleteRenewal = renewalId => http.delete(`/renewals/${renewalId}`);

/**
 * @param {Object} conversation Conversation to create.
 * @param {Object} message First message of this new conversation.
 */
export const createConversationPrescriber = (conversation, message) => http.post(`/conversations/prescriber`, { conversation, message });
export const createConversationPatient = (conversation, message) => http.post(`/conversations/patient`, { conversation, message });

/**
 * @param {String} contractId
 * @returns {Promise}
 */
export const forcePlanification = contractId => http.get(`/interventions/${contractId}/force-planification`);

/**
 * @param {String} contractId
 * @returns {Promise}
 */
export const generateExitAttestation = contractId => http.get(`/patients/generate-exit-attestation/${contractId}`);

export const createExtranetAccount = data => http.post(`/extranet/create-account`, data);
