import { localize } from "@/helpers/_functions/i18n/dictionaryPathResolver";

const localPath = "router.extranet.conversations";

/* eslint-disable import/extensions */
const ConversationsList = () => import(/* webpackChunkName: "conversations" */ "@/modules/conversations/list/ExtranetConversationsList");
const ConversationsAdd = () => import(/* webpackChunkName: "conversations" */ "@/modules/conversations/add/ExtranetConversationsAdd");
const ConversationsView = () => import(/* webpackChunkName: "conversations" */ "@/modules/conversations/view/ExtranetConversationsView");

export default [
  {
    path: "messages",
    name: "conversationsList",
    component: ConversationsList,
    meta: {
      title: localize(localPath, `title--conversations`)
    }
  },
  {
    path: "messages/creation",
    name: "conversationsAdd",
    component: ConversationsAdd,
    meta: {
      title: localize(localPath, `title--conversations`)
    }
  },
  {
    path: "messages/:id",
    name: "conversationsView",
    component: ConversationsView,
    meta: {
      title: localize(localPath, `title--conversations`)
    }
  }
];
