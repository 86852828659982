<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M12,3 C13.0100002,3 13.8451398,3.74866702 13.9807397,4.72132186 C16.6707383,5.40337822 17.7661724,7.66661404 18.2318891,11.5538846 C18.2676123,11.8520603 18.2825197,11.9876206 18.3535442,12.6472828 C18.6363706,15.2741228 18.9125402,16.2589244 19.5376777,16.6557087 C20.3828634,17.1921609 20.0028525,18.5 19.0017932,18.5 L14.9584039,18.5006962 C14.7200781,19.9192103 13.486255,21 12,21 C10.513745,21 9.27992193,19.9192103 9.0415961,18.5006962 L5.00179317,18.5 C4.00075024,18.5 3.62072713,17.1921956 4.46588206,16.6557256 C5.09094575,16.2589605 5.36710311,15.2741807 5.64992771,12.6474534 C5.72096757,11.9876722 5.73587675,11.8521016 5.77160786,11.5538774 C6.23711731,7.66857414 7.33173817,5.40564073 10.0183111,4.72221545 C10.1537228,3.749687 10.989312,3 12,3 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
