const { locationTypes, locationTypesDiabete, locationTypesMedpro } = require("../constants/locationTypes");

const getLocationTypeRespiratory = typeId => {
  return locationTypes.find(type => type._id === typeId);
};

const getLocationTypeDiabete = typeId => {
  return locationTypesDiabete.find(type => type._id === typeId);
};

const getLocationTypeRespiratoryMedpro = typeId => {
  return locationTypesMedpro.find(type => type._id === typeId);
};

const getLocationType = (typeId, platform) => {
  switch (platform) {
    case "bastide": {
      return getLocationTypeRespiratory(typeId);
    }
    case "bastide-diabete": {
      return getLocationTypeDiabete(typeId);
    }
    case "medpro": {
      return getLocationTypeRespiratoryMedpro(typeId);
    }
    default: {
      return getLocationTypeRespiratory(typeId);
    }
  }
};

const getLocationTypes = platform => {
  switch (platform) {
    case "bastide": {
      return locationTypes;
    }
    case "bastide-diabete": {
      return locationTypesDiabete;
    }
    case "medpro": {
      return locationTypesMedpro;
    }
    default: {
      return locationTypes;
    }
  }
};

module.exports = { getLocationType, getLocationTypes, getLocationTypeRespiratory, getLocationTypeDiabete, getLocationTypeRespiratoryMedpro };
