<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 20"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M4 2C4 0.89543 4.89543 0 6 0H12C13.1046 0 14 0.89543 14 2V3H17C17.5523 3 18 3.44772 18 4C18 4.55228 17.5523 5 17 5H16C16 5.04458 15.9985 5.08945 15.9954 5.13458L15.1187 18.1346C15.0479 19.1845 14.1756 20 13.1232 20H4.87673C3.8244 20 2.95207 19.1845 2.88126 18.1346L2.00453 5.13458C2.00151 5.08978 2 5.0449 2 5H1C0.447715 5 0 4.55228 0 4C0 3.44772 0.447715 3 1 3H4L4 2ZM4 5L4.87673 18H13.1232L14 5H4ZM12 3H6V2H12V3ZM7 7C7.55228 7 8 7.44772 8 8V15C8 15.5523 7.55228 16 7 16C6.44772 16 6 15.5523 6 15V8C6 7.44772 6.44772 7 7 7ZM11 7C11.5523 7 12 7.44772 12 8V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V8C10 7.44772 10.4477 7 11 7Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
