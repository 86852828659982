import i18n from "@/i18n";

import NotFound from "@/views/404";
import BadGateway from "@/views/502";
import Unauthorized from "@/views/401";
import Forbidden from "@/views/403";

const i18nKey = "router.app";

export default [
  {
    path: "404",
    alias: "*",
    name: "notFound",
    component: NotFound,
    props: true,
    meta: {
      title: i18n.t(`${i18nKey}.title--notFound`),
      public: true
    }
  },
  {
    path: "502",
    name: "badGateway",
    component: BadGateway,
    props: true,
    meta: {
      title: i18n.t(`${i18nKey}.title--badGateway`),
      public: true
    }
  },
  {
    path: "401",
    name: "Unauthorized",
    component: Unauthorized,
    props: true,
    meta: {
      title: i18n.t(`${i18nKey}.title--unauthorized`),
      public: true
    }
  },
  {
    path: "403",
    name: "Forbidden",
    component: Forbidden,
    props: true,
    meta: {
      title: i18n.t(`${i18nKey}.title--forbidden`),
      public: true
    }
  }
];
