<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M12,3 C13.3737846,3 14.5095219,4.04796716 14.5948541,5.38642614 L14.6,5.5483871 L14.6,15.361 L14.6076585,15.4419746 C14.6530565,15.6983025 14.8952823,15.9032258 15.2,15.9032258 C15.5047177,15.9032258 15.7469435,15.6983025 15.7923415,15.4419746 L15.8,15.3548387 L15.8,12.7741935 C15.8,11.3592877 16.971264,10.2258065 18.4,10.2258065 C19.828736,10.2258065 21,11.3592877 21,12.7741935 C21,13.3264783 20.5522847,13.7741935 20,13.7741935 C19.4477153,13.7741935 19,13.3264783 19,12.7741935 C19,12.4787982 18.7385752,12.2258065 18.4,12.2258065 C18.0952823,12.2258065 17.8530565,12.4307298 17.8076585,12.6870576 L17.8,12.7741935 L17.8,15.3548387 C17.8,16.7697445 16.628736,17.9032258 15.2,17.9032258 C13.771264,17.9032258 12.6,16.7697445 12.6,15.3548387 L12.6,5.5483871 C12.6,5.25299175 12.3385752,5 12,5 C11.6952823,5 11.4530565,5.20492332 11.4076585,5.46125119 L11.4,5.5483871 L11.4,18.4516129 C11.4,19.8665187 10.228736,21 8.8,21 C7.37126399,21 6.2,19.8665187 6.2,18.4516129 L6.2,10.7096774 C6.2,10.4142821 5.93857519,10.1612903 5.6,10.1612903 C5.29528233,10.1612903 5.05305647,10.3662136 5.00765847,10.6225415 L5,10.7096774 C5,11.2619622 4.55228475,11.7096774 4,11.7096774 C3.44771525,11.7096774 3,11.2619622 3,10.7096774 C3,9.2947716 4.17126399,8.16129032 5.6,8.16129032 C6.97378462,8.16129032 8.10952189,9.20925748 8.19485407,10.5477165 L8.2,10.7096774 L8.2,18.4516129 C8.2,18.7470082 8.46142481,19 8.8,19 C9.10471767,19 9.34694353,18.7950767 9.39234153,18.5387488 L9.4,18.4516129 L9.4,5.5483871 C9.4,4.13348128 10.571264,3 12,3 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
