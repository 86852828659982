import i18n from "@/i18n";

const i18nKey = "router.extranet.register";

// eslint-disable-next-line import/extensions
const register = () => import(/* webpackChunkName: "public" */ "@/modules/register/index.extranet");

export default [
  {
    path: "register",
    name: "register",
    component: register,
    meta: {
      title: i18n.t(`${i18nKey}.title--register`),
      public: true
    }
  }
];
