<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M3,8 L3,18 C3,18.5522847 3.44771525,19 4,19 L20,19 C20.5522847,19 21,18.5522847 21,18 L21,8 C21,7.44771525 20.5522847,7 20,7 L4,7 C3.44771525,7 3,7.44771525 3,8 Z M8,7 L8,5 C8,4.44771525 8.44771525,4 9,4 L15,4 C15.5522847,4 16,4.44771525 16,5 L16,7 L16,7 M14.5,13 L12.7888266,15.1962 C12.4493837,15.631857 11.8210412,15.7098538 11.3853842,15.3704109 C11.3203552,15.3197434 11.2618409,15.2612291 11.2111734,15.1962 L9.5,13 L9.5,13 M12,14 L12,11"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMedicalDevice",
  mixins: [iconMixin]
};
</script>
