/* eslint-disable no-shadow */
import moment from "moment";
import { fetchConversation, uploadPrivateFile, fetchFile } from "@/modules/common/_api";
import * as api from "../_api";

const state = {
  conversation: {}
};

const getters = {
  conversation: state => state.conversation
};

const mutations = {
  SET_CONVERSATION: (state, conversation) => {
    state.conversation = conversation;
  }
};

const actions = {
  uploadFile(context, { file, fieldname }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return uploadPrivateFile(formdata);
  },
  fetchConversation(context, idConversation) {
    return new Promise((resolve, reject) => {
      fetchConversation(idConversation)
        .then(res => {
          const { messages, ...conversation } = res.data.body;
          messages.sort((a, b) => new Date(a.sentDate) - new Date(b.sentDate)); // From the oldest to the newest
          conversation.messages = messages.map(message => {
            const sender = conversation.participants.find(user => user._id === message.sender.id);
            return { ...message, sender };
          });
          context.commit("SET_CONVERSATION", conversation);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  addMessage(context, data) {
    return new Promise((resolve, reject) => {
      const { files } = data;
      const date = moment().toISOString();
      const message = {
        ...data,
        sentDate: date
      };
      if (files && files.length) {
        message.files = files;
      }
      api
        .addMessage(message)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  resetConversation(context) {
    context.commit("SET_CONVERSATION", {});
  },
  readMessage(context, id) {
    return api.readMessage(id);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
