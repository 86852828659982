import * as api from "../_api";

const state = {
  appInfos: [],
  supportedLocales: [],
  defaultLocale: ""
};

const getters = {
  appInfos: state => state.appInfos,
  supportedLocales: state => state.supportedLocales,
  defaultLocale: state => state.defaultLocale
};

const mutations = {
  SET_APP_INFOS: (state, data) => {
    state.appInfos = data;
  },
  SET_SUPPORTED_LOCALES: (state, data) => {
    state.supportedLocales = data;
  },
  SET_DEFAULT_LOCALE: (state, data) => {
    state.defaultLocale = data;
  }
};

const actions = {
  getAppInfos(context) {
    api.getAppInfos().then(response => {
      const data = response?.data?.body || [];
      context.commit("SET_APP_INFOS", data);
    });
  },
  setDefaultLocale(context, locale) {
    context.commit("SET_DEFAULT_LOCALE", locale);
  },
  setSupportedLocales(context, locales) {
    context.commit("SET_SUPPORTED_LOCALES", locales);
  }
};

export default { state, getters, mutations, actions, namespaced: true };
