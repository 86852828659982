<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <circle cx="16" cy="16" r="15" class="icon-stroked" />
    <path
      class="icon-stroked"
      d="M3,14.2062233 L8.61564985,20.2576866 C8.99132449,20.6625162 9.6240482,20.686151 10.0288778,20.3104763 C10.0923664,20.2515601 10.1479317,20.1846451 10.1941785,20.1114115 L21,3 L21,3"
      transform="translate(4 4)"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
