import http from "@/services/http";

const { filesSettings } = require("@/bootstrap").settings;

/**
 * @param {File} file
 * @returns {Object} file
 */

export default file =>
  http.post("/upload/public", file, {
    headers: {
      "Content-Type": "multipart/form-data"
    },
    timeout: filesSettings.timeout
  });
