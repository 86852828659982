import * as Sentry from "@sentry/browser";
import storage, { memoryStorage } from "@common/services/storage";

import store from "@/store";

const USER_COLLECTION = "user";
/**
 * setAppUser register the user in local storage (indexedDb by default, localStorage for legacy browsers)
 * It also set the User to be included in Sentry error reports
 * It has a promise based API.
 * storage is an instance of localforage package. See https://localforage.github.io/localForage
 * @param {Object} user
 * @return {Promise}
 */
export const setAppUser = user => {
  Sentry.setUser({ id: user._id, username: `${user.firstname} ${user.lastname}`, email: user.email });
  return storage.setItem(USER_COLLECTION, { isLoggedIn: true, ...user });
};

/**
 * getLocalUser retrieve the user in local storage (indexedDb by default, localStorage for legacy browsers)
 * It has a promise based API.
 * storage is an instance of localforage package. See https://localforage.github.io/localForage
 * @return {Promise}
 */
export const getLocalUser = () => storage.getItem(USER_COLLECTION);

/**
 * removeAppUser removes the user in local storage (indexedDb by default, localStorage for legacy browsers)
 * It also unset the User of Sentry config and clear memoryStorage, as it is closely associated to current session
 * It has a promise based API.
 * storage is an instance of localforage package. See https://localforage.github.io/localForage
 * @return {Promise}
 */
export const removeAppUser = () => {
  Sentry.configureScope(scope => scope.setUser(null));
  memoryStorage.clear();
  return storage.removeItem(USER_COLLECTION);
};

export const clearUserBrowserDatas = () =>
  store
    .dispatch("login/deleteResourceCookie")
    .then(() => removeAppUser().then(() => window.location.reload()))
    .catch(() => {});
