import http from "@/services/http";

export const archivePatient = data => http.put(`/entity/patient/archive`, data);

export const banPatient = data => http.put(`/entity/patient/ban`, data);

export const archivePrescriber = data => http.put(`/entity/prescriber/archive`, data);

export const banPrescriber = data => http.put(`/entity/prescriber/ban`, data);

export const archivePrescribersGroup = data => http.put(`/entity/prescribers-group/archive`, data);

export const banPrescribersGroup = data => http.put(`/entity/prescribers-group/ban`, data);
