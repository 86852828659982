<template functional>
  <svg :width="props.width" :height="props.height" :class="`icon-${props.color}`" viewBox="0 0 24 24">
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g transform="translate(9.125, 8.125)">
        <path
          class="icon-filled"
          d="M6.75,6.375 C6.75,6.58210678 6.58210678,6.75 6.375,6.75 L0.375,6.75 C0.167893219,6.75 0,6.58210678 0,6.375 C0,4.70618092 1.52134962,3.375 3.375,3.375 C5.22865038,3.375 6.75,4.70618092 6.75,6.375 Z M3.375,3 C2.54657288,3 1.875,2.32842712 1.875,1.5 C1.875,0.671572875 2.54657288,0 3.375,0 C4.20342712,0 4.875,0.671572875 4.875,1.5 C4.875,2.32842712 4.20342712,3 3.375,3 Z"
        ></path>
      </g>
      <path
        class="icon-stroked"
        d="M5,8.5 L7,8.5 M5,12 L7,12 M5,15.5 L7,15.5 M6,5.77777778 L6,4.88888889 C6,4.39796911 6.38801988,4 6.86666667,4 L18.1333333,4 C18.6119801,4 19,4.39796911 19,4.88888889 L19,19.1111111 C19,19.6020309 18.6119801,20 18.1333333,20 L6.86666667,20 C6.38801988,20 6,19.6020309 6,19.1111111 L6,18.2222222"
      ></path>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconMailOutlined",
  mixins: [iconMixin]
};
</script>
