<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M5,3 L7.42857143,3 L7.42857143,3 L16.5714286,3 L19,3 C19.5522847,3 20,3.44771525 20,4 L20,21 C20,21.5522847 19.5522847,22 19,22 L5,22 C4.44771525,22 4,21.5522847 4,21 L4,4 C4,3.44771525 4.44771525,3 5,3 Z M9,2 L15,2 C15.5522847,2 16,2.44771525 16,3 C16,3.55228475 15.5522847,4 15,4 L9,4 C8.44771525,4 8,3.55228475 8,3 C8,2.44771525 8.44771525,2 9,2 Z M9,15.5 L15,15.5 M9,18.5 L15,18.5 M11.6296296,7 L12.3703704,7 L12.3703704,9.12962963 L14.5,9.12962963 L14.5,9.87037037 L12.3703704,9.87037037 L12.3703704,12 L11.6296296,12 L11.6296296,9.87037037 L9.5,9.87037037 L9.5,9.12962963 L11.6296296,9.12962963 L11.6296296,7 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconPrescription",
  mixins: [iconMixin]
};
</script>
