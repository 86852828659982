<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M17,3.00216237 C17.5522847,3.00216237 18,3.44988193 18,4.00216237 L18,6.641 L21.5595294,9.04342109 C22.3770725,9.5953439 21.9864054,10.8722316 21,10.8722316 L20,10.8722316 L20,19 C20,20.1045695 19.1045695,21 18,21 L15,21 C14.4477153,21.0001395 14,20.5524242 14,20.0001395 C14,20.000093 14,20.0000465 14.0001395,20 L14.0008371,15 C14.0010536,14.4477153 13.5534008,13.9999376 13.0011161,13.9998605 C13.0010696,13.9998605 13.0010231,13.9998605 13.0009766,14 L11,14 C10.4477153,14 10,14.4477153 10,15 L10,20 C10,20.5522847 9.55228475,21 9,21 L6,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L4,10.8722316 L3,10.8722316 C2.01362482,10.8722316 1.62293628,9.59540765 2.44042207,9.04345387 L10.8808441,3.34461121 C11.5570929,2.88801851 12.4429071,2.88801851 13.1191048,3.34457671 L15,4.614 L15,4.00216237 C15,3.44988193 15.4477153,3.00216237 16,3.00216237 L17,3.00216237 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
