module.exports = {
  externalService: "sap",
  customIdGeneration: "objectId",
  defaultDivisionId: "R",
  handledDivisionIds: ["R"],
  relationPatientIntervenantMode: "zoneId",
  nameFormatInEmail: {
    toPatientTargetName: "firstname",
    toPatientSenderName: "fullname",
    toPrescriberTargetName: "fullname",
    toPrescriberSenderName: "fullname",
    toAdminTargetName: "firstname",
    toAdminSenderName: "fullname"
  }
};
