<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-filled"
      d="M12,9 C12.5522847,9 13,9.44771525 13,10 L13,20 C13,20.5522847 12.5522847,21 12,21 C11.4477153,21 11,20.5522847 11,20 L11,10 C11,9.44771525 11.4477153,9 12,9 Z M12,4 C12.5522847,4 13,4.44771525 13,5 L13,6 C13,6.55228475 12.5522847,7 12,7 C11.4477153,7 11,6.55228475 11,6 L11,5 C11,4.44771525 11.4477153,4 12,4 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
