<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M11.9916658,19 L4.82068947,12.754069 C4.40422866,12.391331 4.36067771,11.7596656 4.72341569,11.3432048 C4.75356894,11.3085857 4.78607041,11.2760843 4.82068947,11.245931 L11.9916658,5 L11.9916658,5 M5,12 L19,12"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
