<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 13 25"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <g transform="translate(-483.000000, -12.000000)" fill-rule="nonzero">
      <g transform="translate(483.674911, 12.390000)">
        <path
          class="icon-filled"
          d="M4.42105263,24 C1.74658869,24 0.0779727096,22.3937622 0.0779727096,19.8128655 C0.0779727096,19.4697856 0.0623781676,17.6998051 0.0545808967,15.5087719 C0.0311890838,11.8908382 0,7.11111111 0,5.59844055 C0,2.90838207 2.19883041,0 5.75438596,0 C9.30994152,0 11.1734893,1.44249513 11.7192982,4.41325536 C11.9298246,5.54385965 11.8596491,9.8245614 11.7738791,14.4951267 C11.7504873,15.5945419 11.7348928,16.4678363 11.7348928,16.8966862 C11.7348928,17.3255361 11.3840156,17.6764133 10.9551657,17.6764133 C10.5263158,17.6764133 10.1754386,17.3255361 10.1754386,16.8966862 C10.1754386,16.4678363 10.1910331,15.5789474 10.214425,14.4639376 C10.2768031,11.4230019 10.3859649,5.76218324 10.1910331,4.70175439 C9.77777778,2.47173489 8.49902534,1.56725146 5.76218324,1.56725146 C3.17348928,1.56725146 1.56725146,3.66471735 1.56725146,5.60623782 C1.56725146,7.11111111 1.59844055,11.8908382 1.62183236,15.5009747 C1.6374269,17.7076023 1.64522417,19.4775828 1.64522417,19.8206628 C1.64522417,21.5438596 2.6042885,22.4483431 4.4288499,22.4483431 C6.83040936,22.4483431 7.79727096,22.3547758 7.79727096,19.9766082 L7.79727096,17.6920078 C7.80506823,14.3391813 7.82066277,8.10136452 7.79727096,7.39181287 C7.75048733,6.01949318 7.36062378,5.87134503 5.83235867,5.87134503 C4.7251462,5.87134503 4.03898635,6.48732943 3.98440546,7.5165692 C3.9454191,8.2417154 3.96881092,14.5653021 3.98440546,17.8089669 C3.98440546,18.2378168 3.64132554,18.588694 3.20467836,18.588694 C2.77582846,18.588694 2.42495127,18.245614 2.42495127,17.8089669 C2.42495127,17.4269006 2.37816764,8.37426901 2.42495127,7.43079922 C2.51851852,5.55945419 3.88304094,4.30409357 5.8245614,4.30409357 C7.10331384,4.30409357 9.23976608,4.30409357 9.3411306,7.3294347 C9.36452242,8.06237817 9.35672515,14.0818713 9.3411306,17.6842105 L9.3411306,19.9688109 C9.3411306,24 6.48732943,24 4.40545809,24 L4.42105263,24 Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
