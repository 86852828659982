import { sendSms, togglePatientSmsConsent, fetchCommunications } from "@/modules/common/_api";

const state = {
  nbCommunications: 0,
  communications: []
};

const getters = {
  nbCommunications: state => state.nbCommunications,
  communications: state => state.communications
};

const mutations = {
  UPDATE_COMMUNICATIONS: (state, communications) => {
    state.communications = communications;
  },
  UPDATE_NB_COMMUNICATIONS: (state, nbCommunications) => {
    state.nbCommunications = nbCommunications;
  }
};

const actions = {
  fetchCommunications: (context, data) => {
    const { patientId, ...params } = data;
    return new Promise((resolve, reject) => {
      fetchCommunications(patientId, params)
        .then(response => {
          context.commit("UPDATE_COMMUNICATIONS", response?.data?.body?.results || []);
          context.commit("UPDATE_NB_COMMUNICATIONS", response?.data?.body?.count || 0);
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  sendSms: (context, smsToSend) => {
    return new Promise((resolve, reject) => {
      sendSms(smsToSend)
        .then(response => {
          resolve(response?.data || {});
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  togglePatientSmsConsent: (context, { patientId, smsConsent }) => {
    return new Promise((resolve, reject) => {
      togglePatientSmsConsent(patientId, { smsConsent })
        .then(response => {
          resolve(response?.data?.body || {});
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions };
