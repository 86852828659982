import merge from "lodash/merge";
import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const setI18nLocale = () => {
  const localeRegistered = localStorage.getItem("user-locale");
  const locale = localeRegistered || process.env.VUE_APP_I18N_LOCALE;
  document.querySelector("html").setAttribute("lang", locale);
  return locale;
};

const setI18nLocaleMessages = () => {
  const localesClient = require.context("@/dictionary", true, /^.*\.[a-z]{2}\.json/i);
  const localesUi = require.context("@ui/dictionary", true, /^.*\.[a-z]{2}\.json/i);

  const messages = {};

  localesClient.keys().forEach(key => {
    const matched = key.match(/\.([a-z]{2})\./i);
    if (matched?.length > 1) {
      const locale = matched[1];
      messages[locale] = merge(messages[locale], localesClient(key));
    }
  });
  localesUi.keys().forEach(key => {
    const matched = key.match(/\.([a-z]{2})\./i);
    if (matched?.length > 1) {
      const locale = matched[1];
      messages[locale] = merge(messages[locale], localesUi(key));
    }
  });

  return messages;
};

export default new VueI18n({
  locale: setI18nLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_LOCALE,
  messages: setI18nLocaleMessages()
});
