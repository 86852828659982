import { searchPatients, searchPrescribers, uploadPrivateFile, fetchFile } from "@/modules/common/_api";

const state = {};

const getters = {
  taxonomies: state => state.taxonomies
};

const mutations = {
  SET_TAXONOMIES: (state, taxonomies) => {
    state.taxonomies = taxonomies;
  }
};

const actions = {
  uploadFile(context, { file, fieldname }) {
    const formdata = new FormData();
    formdata.append(fieldname, file);
    return uploadPrivateFile(formdata);
  },
  searchPatients(context, input) {
    return searchPatients({ query: { input }, skip: 0, limit: 10, source: "local" });
  },
  searchExtranetPatients(context, input) {
    return searchPatients({ query: { input, hasExtranetAccount: true }, skip: 0, limit: 10, source: "local" });
  },
  searchPrescribers(context, input) {
    return searchPrescribers({ query: { input, activeStatus: ["active"] }, skip: 0, limit: 10, source: "local" });
  },
  fetchFile(context, file) {
    return new Promise((resolve, reject) => {
      fetchFile(file)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default { state, getters, mutations, actions, namespaced: true };
