<template functional>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    :class="`icon-${props.color}`"
  >
    <g
      id="outlined-/-action-/-reminders-add"
      class="icon-stroked"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <g id="reminder-add" transform="translate(3, 2)" stroke="inherit" stroke-width="1.5">
        <path
          d="M12.6976515,3.91384979 C11.473602,3.24031076 10.0672621,2.85714286 8.57142857,2.85714286 C3.83755929,2.85714286 0,6.69470214 0,11.4285714 C0,16.1624407 3.83755929,20 8.57142857,20 C10.9383632,20 13.0812204,19.0406102 14.6323438,17.4894867 C15.2073536,16.9144769 15.7010458,16.2581497 16.094691,15.5392346 C16.154464,15.4300711 16.2119302,15.3194646 16.267024,15.2074805"
          id="Path"
        ></path>
        <line x1="0.476190476" y1="3.33333333" x2="4.28571429" y2="0.476190476" id="Line"></line>
        <line x1="16.1904762" y1="2.85714286" x2="12.8571429" y2="0.476190476" id="Line-Copy"></line>
        <polyline id="Path-35" points="8.08042194 7.67762977 8.08042194 11.7437964 10.5842882 14.4322194"></polyline>
        <g id="add" transform="translate(15, 7)" fill-rule="nonzero">
          <line x1="2" y1="0" x2="2" y2="4" id="Path-23"></line>
          <line x1="0" y1="2" x2="4" y2="2" id="Path-24"></line>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  name: "iconClockAdd",
  mixins: [iconMixin]
};
</script>
