<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path
      class="icon-stroked"
      d="M12,17 L12,15 C15.3137085,15 18,12.3137085 18,9 C18,5.6862915 15.3137085,3 12,3 C10.4820995,3 9.09584707,3.56365209 8.03912942,4.49306946 M12,20 L12,21"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
