import commonSettings from "@common/settings/bastide";
import administrationMenu from "./menus/administration.bastide";
import prescriberMenu from "./menus/prescriber.bastide";
import extranetMenu from "./menus/extranet.bastide";
import administrationFooter from "./footer/administration.bastide";
import prescriberFooter from "./footer/prescriber.bastide";
import extranetFooter from "./footer/extranet.bastide";
import administrationDashboard from "./dashboard/administration.bastide";
import supportedLocales from "./supported-locales/supported-locales.bastide";

const sentryDsn = "https://df71b8bc6b6c481f8db2c4ecd2f669c7@o1283494.ingest.sentry.io/1878304";

const titleApp = "Bastide Access";
const name = "Bastide";
const longName = "Bastide Médical";
const extranetContactNumber = "04.66.38.00.20";
const extranetAddresseeName = "Agence Bastide";

const headOffice =
  "Centre d'activité Euro 2000 - 12, avenue de la Dame - 30132 Caissargues <br /> S.A. au capital de 3 303 284, 40 € - R.C. Nîmes B 305 635 039 - SIRET n°305 635 039 00194 - N° TVA Intracommunautaire : FR 19 305 635 039";

export default {
  ...commonSettings,
  administrationDashboard,
  administrationFooter,
  administrationMenu,
  extranetContactNumber,
  extranetMenu,
  headOffice,
  longName,
  name,
  prescriberFooter,
  extranetFooter,
  prescriberMenu,
  sentryDsn,
  supportedLocales,
  titleApp,
  extranetAddresseeName
};
