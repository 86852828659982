<template functional>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    :width="props.width"
    :height="props.height"
    :class="`icon-${props.color}`"
  >
    <path class="icon-stroked" d="M3,5 L21,5 M3,19 L21,19 M3,12 L21,12" />
    <path
      class="icon-filled"
      d="M14,16 C15.6568542,16 17,17.3431458 17,19 C17,20.6568542 15.6568542,22 14,22 C12.3431458,22 11,20.6568542 11,19 C11,17.3431458 12.3431458,16 14,16 Z M8,9 C9.65685425,9 11,10.3431458 11,12 C11,13.6568542 9.65685425,15 8,15 C6.34314575,15 5,13.6568542 5,12 C5,10.3431458 6.34314575,9 8,9 Z M17,2 C18.6568542,2 20,3.34314575 20,5 C20,6.65685425 18.6568542,8 17,8 C15.3431458,8 14,6.65685425 14,5 C14,3.34314575 15.3431458,2 17,2 Z"
    />
  </svg>
</template>

<script>
import iconMixin from "../_icons-mixin";

export default {
  mixins: [iconMixin]
};
</script>
